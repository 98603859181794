import "./news-overview-filter.css";
import "./news-overview-highlights.scss";
import "./news-overview-summary.css";

import "./events-overview.scss";
import "./event.scss";

import * as dompack from "dompack";


let newsoverview_filter;
dompack.register(".newsoverview-filterbar", initFilter);

function initFilter(node)
{
  newsoverview_filter = node;
  node.addEventListener("change", evt => onFilterChange(evt));
}

function onFilterChange()
{
  let selected_types = [];
  for(let checkbox_option of newsoverview_filter.soort.values())
  {
    if (checkbox_option.checked)
      selected_types.push(checkbox_option.value);
  }

  //console.info("[News] selected_types", selected_types);

  let showall = selected_types.length == 0 || selected_types[0] === "";
  let itemscount_highlights = 0
    , itemscount_summary    = 0;

  let newsitems_highlights = document.querySelectorAll(".newsoverviewitem")
  let newsitems_summary    = document.querySelectorAll(".newssummary__item");

  // for(let item of [...newsitems_highlights, ...newsitems_summary])

  //console.log("[News] showall", showall);
  //console.log("[News] highlights", newsitems_highlights);



  // console.info("Highlights");

  for(let item of newsitems_highlights)
  {
    // Show if we want to show all or the category of the item is in our "wishlist"
    let show_item = showall || selected_types.includes( item.dataset.category );

    //console.log("Highlight", selected_types, item.dataset.category);

    if (show_item)
      itemscount_highlights++;

    //console.log(show_item, item);
    item.classList.toggle("newsitem--hide", !show_item);
  }

  // console.info("Summaries");

  for(let item of newsitems_summary)
  {
    // Show if we want to show all or the category of the item is in our "wishlist"
    let show_item = showall || selected_types.includes( item.dataset.category );

    if (show_item)
      itemscount_summary++;

    //console.log(show_item, item);
    item.classList.toggle("newsitem--hide", !show_item);
  }

  console.log(`${itemscount_highlights} highlights and ${itemscount_summary} summary items.`);



  let highlights = document.querySelector(".newsitemhighlights");
  if (highlights)
    highlights.classList.toggle("isempty", itemscount_highlights == 0);


  let newssummary = document.querySelector(".newssummary");
  if (newssummary)
    newssummary.classList.toggle("isempty", itemscount_summary == 0);


  let itemscount_total = itemscount_highlights + itemscount_summary;

  // Set the text which screenreaders will read as feedback
  // so screenreader users know there was an update (and how many results,
  // as they cannot know this by glancing at the screen)
  let feedback_node = document.querySelector(".newsoverview-resultscount");
  if (itemscount_total == 0)
    feedback_node.innerText = "geen nieuwsitem komt overeen";
  else if (itemscount_total == 1)
    feedback_node.innerText = "één nieuwsitem komt overeen";
  else
    feedback_node.innerText = `${itemscount_total} nieuwsitem komt overeen`;
}
