import * as dompack from "dompack";

const months = [ "januari", "februari", "maart", "april", "mei", "juni", "juli", "augustus", "september", "oktober", "november", "december" ];
/** Formats a date in FormatDatetime's %d %B %Y' format
*/
function formatNLReadableDate(date)
{
  return date.getDate() + " " + months[date.getMonth()] + " " + date.getFullYear();
}

export class BaseQuestionType
{
  constructor(questionnode)
  {
    this.questionnode = questionnode;
  }
  checkError(value)
  {
    return "";
  }
  getValue()
  {
    throw new Error("Missing getValue implementation for question of type "  + this.questionnode.dataset.type)
  }
  getTitle()
  {
    return this.questionnode.dataset.questiontitle
  }
  getAnswerText()
  {
    return this.getValue();
  }
  getAnswerForStats()
  {
    return this.getValue();
  }
}

class DateType extends BaseQuestionType
{
  checkError(value)
  {
    if (!value || value == "")
      return "Geen datum ingevuld";

    var datevals = value.split('-');
    if (datevals.length < 3)
      return "Foutieve datum ingevuld";

    // create and validate the date
    try
    {
      var dateval = new Date;

      var year =  datevals[0].toInt();
      var month = datevals[1].toInt() - 1;
      var day =   datevals[2].toInt();

      if (isNaN(day) || day < 1 || day > 31)
        return "Foutieve datum ingevuld";

      if (isNaN(month) || month < 0 || month > 11) /* month is null based */
        return "Foutieve datum ingevuld";

      if (isNaN(year) || year < 1900 || year > 2100)
        return "Foutieve datum ingevuld";

      dateval.set('date',  day);
      dateval.set('month', month);
      dateval.set('year',  year);

      // if we got here, all went well
      if (!dateval.isValid()) // just to be sure
        return "Foutieve datum ingevuld";

      return "";
    }
    catch(err)
    {
      return "Foutieve datum ingevuld";
    }
  }

  getValue()
  {
    let dateday = this.questionnode.querySelector(".date-day").value;
    let datemonth = this.questionnode.querySelector(".date-month").value;
    let dateyear = this.questionnode.querySelector(".date-year").value;
    if(!dateday && !datemonth && !dateyear)
      return "";

    dateday = dateday.padStart(2, "0");
    datemonth = datemonth.padStart(2, "0");
    dateyear = dateyear.padStart(4, "0");

    return dateyear + "-" + datemonth + "-" + dateday;
  }

  getAnswerText()
  {
    const jsdate = new Date(this.getValue());
    return formatNLReadableDate(jsdate);
  }
}

class AmountType extends BaseQuestionType
{
  getValue()
  {
    return this.questionnode.querySelector("input").value;
  }

  checkError(value)
  {
    return value == "" || isNaN(value) ? "Controleer uw invoer" : "";
  }
}

class PulldownType extends BaseQuestionType
{
  getValue()
  {
    return this.questionnode.querySelector("select").value;
  }
  getAnswerText()
  {
    let opt = this.questionnode.querySelector("select").selectedOptions[0];
    return opt ? opt.textContent : "";
  }
  checkError(value)
  {
    if(!value)
      return "Je hebt geen keuze gemaakt.";
    return "";
  }
}
class CheckboxListType extends BaseQuestionType
{
  getValue()
  {
    return dompack.qSA(this.questionnode, "input:checked").map(_ => _.value);
  }
/*
  getValueRecord()
  {
    let answers = dompack.qSA(this.questionnode, "input:checked");

    let answerrecs = [];

    let totalpoints = 0;
    for(let answer of answers)
    {
      let points = answer.hasAttribute('data-points') ? parseInt(answer.getAttribute('data-points')) : 0;

      answerrecs.push(
            { value:      opt.value
            , points:     opt.hasAttribute('data-points') ? parseInt(opt.getAttribute('data-points')) : 0
            });

      totalpoints += points;
    }

    return { value:           opt.value
           , points:          totalpoints
           , selectedoptions: answerrecs
           };
  }
*/
  checkError(value)
  {
    if(value.length == 0 && this.questionnode.dataset.required)
      return "Je hebt geen keuze gemaakt.";
    return "";
  }
  getAnswerText()
  {
    let answers = dompack.qSA(this.questionnode, "input:checked").map(opt => opt.closest(".effecttool__checkboxlist__answer").textContent);
    return answers.join(", ");
  }
  getAnswerPoints()
  {
    let answers = dompack.qSA(this.questionnode, "input:checked");

    let points = 0;
    for(let answer of answers)
      points += answer.hasAttribute('data-points') ? parseInt(answer.getAttribute('data-points')) : 0;

    return points;
  }
}

class RadioType extends BaseQuestionType
{
  getValue()
  {
    let opt = this.questionnode.querySelector("input:checked");
    return opt ? opt.value : "";
  }

  getValueRecord()
  {
    let opt = this.questionnode.querySelector("input:checked");

    return { value:      opt.value
           , points:     opt.hasAttribute('data-points') ? parseInt(opt.getAttribute('data-points')) : 0
           , redirectto: opt.getAttribute('data-redirectto')
           };
  }

  getRedirectURL()
  {
    let opt = this.questionnode.querySelector("input:checked");
    return opt.getAttribute('data-redirectto') ?? "";
  }

  checkError(value)
  {
    if(!value.length)
      return "Je hebt geen keuze gemaakt.";
    return "";
  }
  getAnswerText()
  {
    let opt = this.questionnode.querySelector("input:checked");
    return opt ? opt.closest(".effecttool__radiolist__answer").textContent : "";
  }
  getAnswerPoints()
  {
    let opt = this.questionnode.querySelector("input:checked");
    return opt.hasAttribute('data-points') ? parseInt(opt.getAttribute('data-points')) : 0;
  }
}

let questionhandlers =
  { "date": DateType
  , "amount": AmountType
  , "pulldown": PulldownType
  , "checkboxlist": CheckboxListType
  , "radio": RadioType
  };

export function register(typename, classconstructor)
{
  questionhandlers[typename] = classconstructor;
}

export function getQuestionClass(questionnode)
{
  let type = questionnode.dataset.type;
  if(!questionhandlers[type])
    throw new Error(`Unrecognize question type '${type}'`);
  return new questionhandlers[type](questionnode);
}
