import * as whintegration from "@mod-system/js/wh/integration";
import * as dompack from 'dompack';
import { getTid, getHTMLTid } from "@mod-tollium/js/gettid";
import JSONRPC from "@mod-system/js/net/jsonrpc";
import * as domfocus from 'dompack/browserfix/focus';

import "./feedbackbar.scss";
import "./tags.scss";
import "./teaserbar.scss";

//import * as multiselect from "../form_multiselect/index.es";

import "@mod-acoi/webdesigns/site/site.lang.json";

import * as pagination from "../spc-pagination";

/*

** ACOI Filteredoverview

FIXME:
- test serverside pagination (adding page and resultsperpage fields to return value)

ADDME:
- "rpc" -> "rpc-html" and "rpc-template" ?

!! breaking changes
..

V2 (4TU<TUE<inGenious ?)

V2.1 (ACOI)
- event delegation for filteredoverview toggle this.currentpage
- callback for when expanding or collapsing filters
- public method setFilters()
- public method resetFilters()
- reset form event also handled
- FIXME: using .header-menubar to measure amount of content at the top which is fixed/sticky to determine where to scroll to
  (change this... to either have an callback which can give this height OR ensure scroll-margin-top is used on the resultscontainer)

- teaserbar using (more) CSS variables
- tags using CSS variables
- radio's are also picked up as tag

- added the options.tags_remap callback
- FIXME: removing a tag now correct set's either the value from this.options.defaultfilters OR the first item

V2.2 (ACOI)
- 12 dec: better documentation for method "rpc"
- 12 dec: options.onafterrefresh
- 12 dec: isFiltersActive() from minfin_wig but ALSO using defaultfilters to check if a field is active
- 14 dec: setFilters() and prefillFormWithFilterValues() will now also set fields which have "" as value
- 14 dec: added options.ongotrpcresults - currently only used if method is "rpc"
- 14 dec: added options.debug_tags
- 21 dec: ontagremove

V2.3 (ACOI 2023-march)
- added "resultsperpage"
- options.onafterrefresh now also works for clientside and custom filtering (was rpc only before)

!! FIXME: merge with TUE 2.3 VERSION

V2.4 (ACOI 2023-may) - work-in-progress
- added "statestorageid" for allowing the FilteredOverview to persist/restore the filters when the user returns

V2.4.1 (ACOI/ZVI)
- 2 jul: pagination now working for RPC
- 3 jul: fixed hiddenfilters appearing on the URL

V2.4.2 (ACOI & Utwente_PPP)
- 27 nov: warn if data-filterdata attribute isn't supplied in combinatie with clientside method

V2.4.3
- added rescanFilterNodes() (for supported swapping in and out filters - for example to switch between differently rendered desktop and mobile filters)

Usage:

- tracking
  To enable tracking make sure import "@mod-publisher/js/analytics/gtm"; is loaded

- use a data-filtertagtitle="" to specify the title to use in the taglist tag.
  (for example if you don't want the counter shown in a label in the tag or if you want to abbreviate the title in the tag)

Options

Type
This will determine who is responsible to update/filter what items are shown.

type:
- "clientside"
  Filtering is done clientside or partially.
  Partially means that "fields_rpc" is used. Fieldnames added here will be send to the RPC
  and the RPC must return the id's of all items which match using the specified fields.

  Each item must have an data-filterdata with the metadata as JSON.

- "rpc"
  Filtering is done by sending all filters to the specified RPC+function.
  The RPC function must provide:
  - a field "resultshtml" which contains the new HTML to place in the node specified in options.node_results
  - a field "totalcount" which contains the total amount of results (including all pages if paginated)


  - rpc
  - rpcsearchfunction
  - getfiltersforrpc  - (optional) callback, it it passed the filters and must return the filters to use for the RPC
  - node_results      - Node in which to store HTML returned by the RPC searchfunction

  For a module RPC use: { rpc: new JSONRPC({ url: "/wh_services/modulename/rpc_name/" }) }
  For page RPC use:     { rpc: new JSONRPC(); }

- "serverside"

  Means a normal form submit is used. (so the page will essentially reload, which we seldom actually want)

- "custom"

  A callback will used which should update the items.

  - options.onfilterchange




TODO:
- verify correct GA event usage
- check if filtermatchcallbacks works correctly
- merge this.filters and this.filters_titles into this.filters = [{ value: "", title: "" }] ?

*/

window.__filteredoverviews = [];


/** @short Basic filtered overview support for pages which already handle stuff themselves.

    - Header toggle
    - API for expanding/collapsing the header filters
    - API to set 'X results' teaser for mobile screens


.filteredoverview__form
.filteredoverview__toggleaction
html.filteredoverview-showfilters

*/
export class FilteredOverviewHeader
{
  constructor(formnode, options)
  {
    window.__filteredoverview = this;
    window.__filteredoverviews.push(this);

    if (!options)
      options = {};

    this.options = options;
    // console.log("[filteredoverview] this.options", this.options);

    this.class_showfilters = "filteredoverview--showfilters";

    // let formnode = document.querySelector(".filteredoverview__form");
    if (!formnode)
    {
      console.error(".filteredoverview__form NOT FOUND");
      return;
    }

    if (formnode.initialized)
    {
      console.error("[filteredoverview] Already initialized on this form node!");
      return;
    }

    formnode.initialized = true;
    this.form = formnode;

    document.body.addEventListener("click", evt => this.doCheckforFilterToggle(evt));

    /*
    for( let node of document.body.querySelectorAll(".filteredoverview__toggleaction") )
    {
      // NOTE: We use mouseup to fire before (and be able to prevent) focus
      //       This way we can prevent the focus ring on mouse events, but keep it for keyboard navigation
      node.addEventListener("mousedown", evt => { evt.preventDefault(); }); // prevent getting focus
      node.addEventListener("mouseup",   evt => { this.toggleFiltersExpanded() });
      node.addEventListener("keydown",   evt => this.toggleFiltersExpandedIfEnter(evt) );
    }
    */
  }

  doCheckforFilterToggle(evt)
  {
    // console.log(evt.target);

    let closebutton = evt.target.closest(".filteredoverview__toggleaction");
    if (!closebutton)
      return;

    this.toggleFiltersExpanded();
  }

  onFilterChange(evt)
  {
    this.refreshResults(true);
  }

  onSubmit(evt)
  {
    evt.preventDefault();
    this.refreshResults(true);
  }


  ////////////////////////////////////////////////////////////////////////////////////////
  //
  //  Public misc

  scrollResultsIntoView()
  {
    //let stickyheaderbottom = document.querySelector(".header-projectpage").getBoundingClientRect().bottom;
    let stickyheaderbottom = document.querySelector(".header-menubar").getBoundingClientRect().bottom;
    let resultstop = this.options.resultsanchor.getBoundingClientRect().top;

console.info("sticky header height", stickyheaderbottom);

    let scrollY = resultstop + document.scrollingElement.scrollTop - stickyheaderbottom;
    window.scrollTo({ top: scrollY, left: 0, behavior: "smooth" });
  }


  ////////////////////////////////////////////////////////////////////////////////////////
  //
  //  Filterbar expanding/collapsing


  /// Set whether the filters are expanded or collapsed
  setFiltersExpanded(expand)
  {
    document.documentElement.classList.toggle(this.class_showfilters, expand);

    if (!expand && window.bLazy) // Page content becoming visible again
      window.bLazy.revalidate();

    let fieldnodes = this.form.querySelectorAll("input[name], select[name]");

    // NOTE: using visibility: hidden; (?) on the panel also removed it from the tab navigation list
    //       However we would like this to work automatically without needing to remember those kind of workarounds.
    for(let idx = 0; idx < fieldnodes.length; idx++)
    {
      if (expand)
        fieldnodes[idx].removeAttribute("tabindex");
      else
        fieldnodes[idx].setAttribute("tabindex", "-1"); // disable ability to focus
    }

    this.checkNeedToShowTeaserBar();

    if (expand && this.options.onexpandfilters)
      this.options.onexpandfilters();
    else if (!expand && this.options.oncollapsefilters)
      this.options.oncollapsefilters();
  }

  clickedOnFilterBar()
  {
    this.setFiltersExpanded(false);
    this.scrollResultsIntoView();
  }

  toggleFiltersExpandedIfEnter(evt)
  {
    if (evt.key == "Enter")
      this.toggleFiltersExpanded();
  }

  toggleFiltersExpanded()
  {
    let expand = !document.documentElement.classList.contains(this.class_showfilters);
    this.setFiltersExpanded(expand);

    // window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }

  isFilterBarExpanded()
  {
    return document.documentElement.classList.contains(this.class_showfilters);
  }


  ////////////////////////////////////////////////////////////////////////////////////////
  //
  //  Teaser bar

  setTeaserResultCount(totalfound, totalshown)
  {
    let teasetext = "";

    if (this.options.getfloatingteasertext)
      teasetext = this.options.getfloatingteasertext(totalfound, totalshown);
    else
      console.error("No getfloatingteasertext");

    this.setHoveringFeedbackText(teasetext);
  }


  setHoveringFeedbackText(text)
  {
    //console.info("!!! setHoveringFeedbackText", text, this.resultscountnode);

    if (!this.resultscountnode)
      this.__createResultsCountNode();

    this.resultscountnode.textContent = text;
  }

  __createResultsCountNode()
  {
    /*
    let container = document.createElement("a");
    container.className = "filteredoverview__teaser";
    container.href = "#results";
    */

    // We need a dialog to be at the toplayer, over the filters modal dialog.
    let container = document.createElement("dialog");
    container.className = "filteredoverview__teaser";

    let button = document.createElement("button");
    button.className = "filteredoverview__teaser__button";
    // container.addEventListener("click", evt => this.setFiltersExpanded(false));
    button.addEventListener("click", evt => this.clickedOnFilterBar());
    container.appendChild(button);

    let label = document.createElement("div");
    label.className = "filteredoverview__teaser__content filteredoverview__teaser__label";
    button.appendChild(label);
/*
    let label = document.createElement("div");
    label.className = "filteredoverview__teaser__label";
    contentnode.appendChild();
*/
    /*
    label.tabIndex = 0;
    label.addEventListener("keydown", evt => this.toggleFiltersExpandedIfEnter(evt) );
    contentnode.appendChild(label);

    let closebtn = document.createElement("div");
    closebtn.className = "filteredoverview__teaser__close";
    closebtn.textContent = "Close";
    contentnode.appendChild(closebtn);
    */

    document.body.appendChild(container);

    this.node_teaserbar = container;
    this.resultscountnode = label;

    window.addEventListener("resize", evt => this.checkNeedToShowTeaserBar(evt));
    document.addEventListener("scroll", evt => this.checkNeedToShowTeaserBar(evt));

    this.checkNeedToShowTeaserBar();
  }

  checkNeedToShowTeaserBar()
  {
    if (!this.node_teaserbar || !this.options.resultsanchor)
      return;

    // let viewportheight = document.body.getBoundingClientRect().height;
    let viewportheight = document.documentElement.clientHeight;

    let feedbackbartop = this.options.resultsanchor.getBoundingClientRect().top;


    let results_not_visible = feedbackbartop > viewportheight
                              || document.querySelector("dialog[open]") !== null;

    /*
    console.log({ resultsnode: this.resultsnode
                , feedbackbartop: feedbackbartop
                });
    */

    // The feedback bar also tells the amount of results AND it means whe're almost at the results.
    // So if we reach the top of the feedback bar we can hide the teaser bar.
    this.node_teaserbar.classList[results_not_visible ? "add" : "remove"]("filteredoverview__teaser--aboveresults");
  }
}
//


/** @short FilteredOverview

    - The class you make MUST use doFilter() or doFilterForce() to trigger a refresh
    - The class you make may offer a showResults() which uses this.filters to change the content on the page

*/
export default class FilteredOverview extends FilteredOverviewHeader
{
  constructor(formnode, options)
  {
    options = { trackingid:             "" // name to identify this for tracking (or debug) purposes - will be send to the dataLayer for tracking
              , statestorageid:         "" // name to store the filters used in the session storage

              , additionalform:         null
              , resultsanchor:          null

              /** Initial values for the form fields.
                  These will be set if the URL didn't contain a field/value which counts as "active".
                  (ignored fields defined in options.fields_dontcountasactive and fields
                   which value matches the one in options.defaultfilters)
                */
              , defaultfilters:         {}

              /** Fixed filter values which aren't meant for the user to change.
                  These can be used to pass state. For example:
                  - Pass the id of the site so it'll show items for the correct test or live site
                  - Pass the language of the page/site so the RPC will only return English articles
                  - Pass which sets of tags the RPC needs to render

                  These fields will
                  - NOT appear in the URL
                  - NOT appear as a tag in the feedback bar
              */

              , hiddenfilters:          {}
              , showsummary:            true   // whether to create a list of tags corresponding to all selected filters (these also work to quickly removed a filter)

              , method:                 "clientside"

              // Tags
              , tags_remap:             null

              // Fields used for method "clientside"
              , fields_hidetag:           []   // fields which must not appear as a tag
              , fields_notforfiltering:   []   // fields which must not be used for filtering (but do appear on the URL) -- FIXME: also remove these before sending to RPC
              , fields_dontcountasactive: []   // fields which does not count towards an "active filter" (such as sorting options, pagination, invisible fields)
              , fields_rpc:               []   // type:"clientside" - fields which require the usage of the RPC
              , fields_textmatch:         []   // type:"clientside" - do partial textmatch for these fields
              , filtermatchcallbacks:     {}   // (FIXME: untested) map with a function per filter field which given the filter value and item data will return true if it's a match
              , finalize_itemmatch:       null // final callback used in isMatch() to remove more matches
              , sortorderings:            {}   // object in which the key is the sort-value and the value contains an array of id's in the correct order (each item must have an "id" field in the filterdata - which is JSON encoded in data-filterdata of each item element)
              , resultsperpage:           0    // 0 = don't pagina, >1 = results per page (only if method is "clientside")

              // Fields used for method "rpc"
              , rpc:                    null
              , rpcsearchfunction:      ""
              , getfiltersforrpc:       null
              , node_results:           null
              , node_pagination:        null // container to create clientside pagination in

              // Fields used for all methods //method "custom"
              , onfilterchange:         null // in "custom" use it for refreshing/filters.. in other to influence filters (instance.filters)
              , ongotrpcresults:        null // after the RPC responded and before refreshing using these results this callback will be used with the results as parameter
              , onafterrefresh:         null
              , onremovetag:            null // code to handle before the removal of the tag is done.. if it returns true, the filteredoverview doesn't need to remove the value itself

              , getfloatingteasertext:  null

              , debug:                  false
              , debug_rpc:              false
              , debug_ordering:         false
              , debug_tags:             false
              , debug_matching:         false

              , ...options
              };
    super(formnode, options);

    if (this.debug)
      console.log("[filteredoverview] options", options);

    this.debug = location.href.indexOf("debugfilteredoverview") > -1;
    this.debug_search = location.href.indexOf("debugfilteredoverview") > -1;
    this.filters = {};
    this.filters_titles = {};
    this.pagination_by_rpc = false; // automatically set to true if the RPC returns an "page" and "resultsperpage" field

    // fields to trach what filters have been reported using the window.dataLayer (to Google Tagmanager)
    this.filter_lastseenkeys = [];
    this.filter_lastsettings = {};

    this.items = [];
    this.visibleitems = [];

    this.currentpage = 0;
    this.resultscount = 0;
    this.resultsperpage = this.options.resultsperpage; // RPC can override this

    if (!this.options.resultsanchor)
    {
      this.options.resultsanchor = document.body.querySelector('[id="results"]');

      if (!this.options.resultsanchor)
      {
        // NOTE: rather not use <a id="" /> because accessibility checkers will complain the link doesn't have a href
        console.error(`[filteredoverview] Must add an <div id="results"> to scroll to when using the teaser bar`);
      }
    }


    if (window.dataLayer)
    {
      if(!this.options.trackingid)
        console.error("Please set the trackingid for this page (for tracking)");
      else
        dataLayer.push({ filterpage: this.options.trackingid });
    }


    if (!this.areOptionsValid())
      return;


    if (this.options.method == "clientside")
    {
      // Check if we have the information to do clientside filtering
      if (this.options.items.length > 0)
      {
        if (!this.options.items[0].dataset.filterdata)
          console.error(`method clientsite required a data-filterdata attribute with JSON content for every item.`);
        else if (this.options.fields_rpc.length > 0 && !this.options.items[0].id)
          console.error('method "clientside" with options.fields_rpc usage (usually for text searches) requires the (whfs)id to be within the filterdata of each item.');
      }

      for (let item of this.options.items)
      {
        let itemdata = item.dataset.filterdata ? JSON.parse(item.dataset.filterdata) : null;
        this.items.push({ node:       item
                        , filterdata: itemdata
                        });
      }

      if (this.options.debug)
        console.log("FilteredOverview] Gather all itemdata:", this.items);
    }


    this.feedbacknode = document.body.querySelector(".filteredoverview__feedback");
    this.resultsnode = document.body.querySelector(".filteredoverview__results");

    if (this.debug)
    {
      this.form.classList.add("filteredoverview--debugmode");

      console.log({ fbn: this.feedbacknode
                  , rsn: this.resultsnode
                  });
    }


    // Check for removing tags on pages which show selected filters as tags
    let filtertagscontainer = document.querySelector(".filtertags__items");
    if (filtertagscontainer)
      filtertagscontainer.addEventListener("click", evt => this.doCheckForTagRemoval(evt));


    this.rescanFilterNodes();


    if (this.debug)
      console.info("[filteredoverview] field nodes: ", this.filternodes);

    this.filters = this.getInitialFilterValues();
    console.info("[filteredoverview] Determined initial form value to be", this.filters);

    this.setFiltersWithoutRefresh(this.filters);

    this.initFormEvents();
  }


  /** @short update the list of nodes (for example in case of switching out/in a set of mobile or desktop specific filters)
   */
  rescanFilterNodes()
  {
    // Make a list of filter fields
    this.filternodes = this.form.querySelectorAll("input[name], select[name]");
    if (this.options.additionalform)
    {
      let additionalfields = dompack.qSA(this.options.additionalform, "input[name], select[name]");
      this.filternodes = [ ...this.filternodes, ...additionalfields ];
    }

    this.alloptions = this.getAllFilterOptions(this.filternodes);
    console.info("alloptions", this.alloptions);

    for( let node of this.filternodes)
    {
      if (!node.__fo_initialized)
      {
        node.addEventListener("change", ev => this.onFilterChange(ev));
        node.__fo_initialized = true;
      }
    }
  }


  /** @short
   *
   *  1. use direct link with prefills
   *  2. use stored
   *  3. use defaults as specified through Javascript
   *  4. empty (leaving all values as they are in the DOM)
   */
  getInitialFilterValues()
  {
    console.info("[filteredoverview] getInitialFilterValues");
    this.oldfilterstr = ""; //JSON.stringify(this.filters);

    let filters = this.getFiltersFromURL();
    // console.info("Filters from URL", this.filters);


    // Did the URL have active fields?
    // (because fields such as sort or viewtype don't determine what items
    // are matched/shown, but rather influence HOW the matches are shown)
    if (this.isFilterActive())
    {
      console.info("[filteredoverview] initial filters set to URL values");
      return filters;
    }


    // Check if there are filters stored from a previous
    // visit to the page in the current session.
    if (this.options.statestorageid
        && (this.options.statestorageid in sessionStorage))
    {
      let filters = {};
      try
      {
        // console.info("STORED", sessionStorage[this.options.statestorageid]);
        filters = JSON.parse(sessionStorage[this.options.statestorageid]);
        // console.log("PARSED", filters);
      }
      catch(error)
      {
        console.warn("[filteredoveriew] Failed to parse stored filters.");
      }

      if (filters)
      {
        console.log("[filteredoverview] initial filters restored from sessionStorage");
        return filters;
      }
    }


    // If we didn't get any filters through the URL use defaultfilters if available
    if (!this.isEmpty(this.options.defaultfilters))
    {
      console.info("[filteredoverview] initial filters set to options.defaultfilters", this.options.defaultfilters);
      return this.options.defaultfilters;
    }

    // Use the filters from the URL
    console.info("[filteredoverview] initial filters empty (leaving selection/values in DOM intact)");
    return filters;
  }

  isEmpty(obj)
  {
    for (const prop in obj) {
      if (Object.hasOwn(obj, prop)) {
        return false;
      }
    }

    return true;
  }



  areOptionsValid()
  {
    if (this.options.method == "rpc")
    {
      if (!this.options.rpc || !this.options.rpcsearchfunction)
      {
        console.info('Option "rpc" and "rpcsearchfunction" must be specified when using the method "rpc".');
        return false;
      }
    }
    else if (this.options.method == "serverside")
    {
      // validate ?
    }
    else if (this.options.method == "clientside")
    {
      // We require an options.items with the array of nodes we must filter
      if (!("items" in this.options))
      {
        console.info('Option "items" must be specified with the nodes to filter when using method "clientside".');
        return false;
      }
    }
    else if (this.options.method == "custom")
    {
      if (!this.options.onfilterchange)
      {
        console.info('Option "onfilterchange" must be specified when using method "custom"');
        return;
      }
    }
    else
    {
      console.error('Please specificy method: "serverside", "rpc", "clientside" or "custom" for FilteredOverview');
      return;
    }

    return true;
  }


  /** @short update the specified filters (filters that aren't specified are left as they are)
   */
  setFilters(filters)
  {
    this.setFiltersWithoutRefresh(filters);
    this.refreshResults(false); // no user-interaction refresh (for tracking)
  }

  setFiltersWithoutRefresh(filters)
  {
    if (this.debug)
      console.info("[FilteredOverview] setFilters", filters);

    this.prefillFormWithFilterValues(filters);
    this.updateURL(filters);

    if (window.multiselect) // FIXME
      multiselect.refreshAll();
  }




  initFormEvents()
  {
    if (this.debug)
      console.info("[filteredoverview] initChangeEvents");

    // submit (by enter, 'go' on virtual keyboard or click on a submit button) must force a refilter
    this.form.addEventListener("submit", evt => { this.onSubmit(evt); });
    this.form.addEventListener("reset", evt => this.doClearFilters(evt));

    if (this.options.additionalform)
      this.options.additionalform.addEventListener("submit", evt => { this.onSubmit(evt); });
  }


  getFiltersFromURL()
  {
    let filters = {};

    //get url params if set
    let urlparamsdone = [];
    for( let node of this.filternodes)
    {
      let val = urlparamsdone.indexOf(node.name) == -1 ? this.getUrlParam(node.name) : "";

      if(val != "")
      {
        let inptype = node.nodeName == "INPUT" ? node.getAttribute("type") : "";
        if( inptype == "checkbox")
        {
          let vals = val.split(",");

          filters[ node.name ] = vals;
        }
        else if(inptype == "radio")
          filters[ node.name ] = val;
        else if(node.nodeName == "SELECT")
          filters[ node.name ] = val;
        else
          filters[ node.name ] = val;

        urlparamsdone.push( node.name );
      }
    }

    filters = { ...filters, ...this.options.hiddenfilters };

    return filters;
  }

  /** @short update the filters in the DOM (this doesn't trigger updating the internal filter values)
   */
  prefillFormWithFilterValues(filters)
  {
    console.log("Apply filters to DOM", filters);

    for( let node of this.filternodes )
    {
      if (!(node.name in filters))
        continue;

      let inptype = node.nodeName == "INPUT" ? node.getAttribute("type") : "";

      if(inptype == "checkbox")
      {
        let val = filters[ node.name ];
        if (val)
        {
          if (val === true || val === false)
            node.checked = val;
          // else if Array.isArray(filters[ node.name ])
          else // assume we got a array of strings
            node.checked = filters[ node.name ].indexOf(node.value) > -1;
        }
      }
      else if(inptype == "radio")
        node.checked = filters[ node.name ] == node.value // ? true : false;
      else if(node.name in filters) //( filters[ node.name ] )
      {
        // console.log(">>>>> setting", node.name, "to", filters[node.name]);
        node.value = filters[ node.name ];
      }
    }
  }

  // Set filter parameters in url
  updateURL(filters)
  {
    // console.log("Apply filters to URL", this.filters);

    let url = this.getURLForFilters(filters);

    history.replaceState(null, null/*WHBase.config.obj.title*/, url);
  }


  // FIXME!: have a way for radiobuttons without any selection
  //         to properly have a field with the includeemptyfields setting.
  getFiltersFromForm(evt, includeemptyfields)
  {
    return this.getFiltersFromNodes(this.filternodes, includeemptyfields)
  }

// added so we can do faceted search
  // (when we have all options we can for each field which needs the number badges iterate through all options)
  getAllFilterOptions(filternodes)
  {
    let filters = {};

    for(let node of filternodes)
    {
      if(node.nodeName == "SELECT") //node.value != "") // <select> (pulldown) or <input type="text" />
      {
        if (node.name in filters)
          console.error("Some field name occured twice");

        let foptions = [];
        for(let option of node.options)
        {
          let entry = { node:      node
                      , name:      node.name
                      , value:     node.value
                      , value_url: option.getAttribute("data-urlvalue")
                      , title:     option.text
                      , optionnode: option
                      , labelnode: option
                      };
          foptions.push(entry);
        }

        filters[node.name] = foptions;
      }
      else if (node.nodeName == "INPUT")
      {
        let inptype = node.getAttribute("type");
        if(inptype == "checkbox" || inptype == "radio")
        {
          let entry = { node:      node
                      , name:      node.name
                      , value:     node.value
                      , value_url: node.getAttribute("data-urlvalue")
                      , title:     this.getInputLabelText(node)
                      , optionnode: node.closest(".radiolist__option")
                      , labelnode: document.querySelector('label[for="'+node.id+'"]:not(:empty)')
                      };

          if (!filters[node.name])
            filters[node.name] = [];
          filters[node.name].push(entry);
        }
      } // NOTE: other types of input aren't supported
    } // iterating filternodes

    return filters;
  }

  getFiltersFromNodes(filternodes, includeemptyfields)
  {
    includeemptyfields = !!includeemptyfields; // change to boolean

    let filters = {};
    let filters_titles = {};

    let tags = []; // Each value as seperate item
    let filterrecs = {}; // Items grouped under their form field name + including empty values


    for(let node of filternodes)
    {
      if(node.nodeName == "SELECT") //node.value != "") // <select> (pulldown) or <input type="text" />
      {
        if (includeemptyfields || node.value != "")
        {
          let option = node.options[node.selectedIndex];
          let title = option.text;

          filters[ node.name ] = node.value;
          // FIXME: we could use .selectedOptions, but it might fail on IE11 ?
          filters_titles[ node.name ] = title; // node.value;

          let entry = { node:      node
                      , name:      node.name
                      , value:     node.value
                      , value_url: option.getAttribute("data-urlvalue")
                      , title:     title
                      };
          tags.push(entry);

          if (!filterrecs[node.name])
            filterrecs[node.name] = [];

          filterrecs[node.name].push(entry);
        }
      }
      else if (node.nodeName == "INPUT")
      {
        let inptype = node.getAttribute("type");


        //////////////////////////////////////////////////////
        if(inptype == "checkbox" || inptype == "radio")
        {
          if(!node.checked)
            continue;

          let title = this.getInputLabelText(node);

          let entry = { node:      node
                      , name:      node.name
                      , value:     node.value
                      , value_url: node.getAttribute("data-urlvalue")
                      , title:     title
                      };

          tags.push(entry);

          if (!filterrecs[node.name])
            filterrecs[node.name] = [];
          filterrecs[node.name].push(entry);


          if (inptype == "checkbox")
          {
            if(!filters[ node.name ])
            {
              filters[node.name] = [];
              filters_titles[node.name] = [];
            }

            filters[ node.name ].push( node.value );
            filters_titles[ node.name ].push( title );
          }
          else // "radio"
          {
            if (node.value != "") // ignore invalid (placeholder) or 'show all' settings
            {
              filters[ node.name ] = node.value; //checked;
              filters_titles[ node.name ] = title;
            }
          }
        }
        else // assume textual ("text", "search")
        {
          if (node.value != "")
          {
            let entry = { node:      node
                        , name:      node.name
                        , value:     node.value
                        , value_url: null
                        , title:     node.value
                        };
            tags.push(entry);

            if (!filterrecs[node.name])
              filterrecs[node.name] = [];
            filterrecs[node.name].push(entry);

            filters[ node.name ] = node.value;
            filters_titles[ node.name ] = node.value;
          }
        }
        //////////////////////////////////////////////////////


      }
    }

    filters = { ...filters, ...this.options.hiddenfilters };



    // if (this.options.debug)
    {
      console.log("[filteredoverview] getFiltersFromForm() result");
      console.log("[filteredoverview] Filters", filters);
      console.log("[filteredoverview] Filter titles", filters_titles);
    }


    //this.onFormFiltersUpdate(filters, evt);

    /*
    console.log("Filters after update", filters);
    console.groupEnd();
    */
    return { filters:        filters
           , filters_titles: filters_titles
           , filterrecs:     filterrecs
           , tags:           tags
           };
  }

  getInputLabelText(node)
  {
    let title = "";

    if (node.hasAttribute("data-filtertagtitle")) // used by PDC, ACOI
    {
      title = node.getAttribute("data-filtertagtitle");
    }
    else if (node.id != "")
    {
      // NOTE: the not empty is for cases where an empty label is used for (old) styled checkbox/radiobutton component
      let labelnode = document.querySelector('label[for="'+node.id+'"]:not(:empty)');

      if (labelnode)
        title = labelnode.textContent;
      else
        title = "??"; // no label or there's a typo in the label's for attribute
    }

    return title;
  }



  resetFilters(evt)
  {
    // console.info("[FilteredOverview] resetFilters()")
    this.form.reset();

    // if (evt) // prevent after resetting or whe'll block the reset
      // evt.preventDefault();

    if (this.options.defaultfilters)
      this.prefillFormWithFilterValues(this.options.defaultfilters);

    // delay refresh until after the filters have been cleared
    setTimeout(this.refreshResults.bind(this,true), 0);
  }


  doTracking(interactivechange)
  {
    if(interactivechange)
    {
      this.sendCurrentFiltersToGoogleAnalytics(interactivechange);
      this.sendCurrentFiltersToDataLayer(interactivechange);
    }

    this.filter_lastseenkeys = Object.keys(this.filters_titles);
  }


  sendCurrentFiltersToGoogleAnalytics()
  {
    console.info({ hitType:       "event"
              , eventCategory: this.options.trackingid
              , eventAction:   "filter"
              , eventLabel:    this.getURLForFilters(this.filters) // optional
              });

    if (window.ga)
    {
      ga( "send"
        , { hitType:       "event"
          , eventCategory: this.options.trackingid
          , eventAction:   "filter"
          , eventLabel:    this.getURLForFilters(this.filters) // optional
          });
    }
    else
      console.log("Not using Google Analytics");
  }


  // Used for Google Tagmanager
  sendCurrentFiltersToDataLayer()
  {
    if (!window.dataLayer)
      return;

    let dlevent = { event: "set_filters"
                  };
    Object.keys(this.filters_titles).forEach(key=>
    {
      // Convert an array (whether it's filled with strings or integer's) to a comma seperated string
      let val = this.filters_titles[key];
      if(Array.isArray(val))
        val = val.join(', ');

      dlevent['filter_' + key] = val;

      if(!this.filter_lastsettings[key] || this.filter_lastsettings[key] != val) //also send an explicit event for just this filter
      {
        this.filter_lastsettings[key] = val;
        dataLayer.push({ event: 'set_filter', filtername: key, filtervalue: val});
      }
    });
    this.filter_lastseenkeys.filter(key => !(key in this.filters_titles)).forEach(key => //this key went away
    {
      dataLayer.push({ event: 'set_filter', filtername: key, filtervalue: ''});
    });

    dataLayer.push(dlevent);

    // if (this.options.debug)
    // console.info(dataLayer);
  }



  async refreshResults(interactivechange)
  {
    if (this.options.debug)
      console.info("refreshResults");

    if (this.options.method == "rpc")
    {
      this.refreshResultsFromRPC(interactivechange);
    }
    else if (this.options.method == "clientside")
    {
      this.refreshResultsByFiltering(interactivechange);
      // this.currentpage = 0;
      this.onAfterRefreshResults();
    }
    else if (this.options.method == "custom")
    {
      this.refreshResultsByCallback(interactivechange);
      this.onAfterRefreshResults();
    }
  }

  onAfterRefreshResults()
  {
    if (!this.pagination_by_rpc)
      this.currentpage = 0;

    this.refreshPagination();

    if (this.options.onafterrefresh)
    {
      console.info("[FilteredOverview] Calling onafterrefresh");
      this.options.onafterrefresh();
    }

    if (window.bLazy)
      window.bLazy.revalidate();
  }

  async refreshResultsByCallback(interactivechange)
  {
    this.__refreshShared(interactivechange);
    // this.options.onfilterchange();
  }


  async refreshResultsByFiltering(interactivechange)
  {
    console.log("[FilteredOverview] Filtering", this.items.length, "items (clientside)");

    this.__refreshShared(interactivechange);

    let totalfound = 0;


    //Do text search server-side /////////////////////////////////////////////////////////////////////////////////////////
    let rpc_field_used = false;
    if (this.options.fields_rpc.length > 0)
    {
      for (let key of this.options.fields_rpc)
      {
        if (key in this.filters)
          rpc_field_used = true;
      }
    }

    // if (this.filters.query)
    if (rpc_field_used)
    {
      //console.info("An field which requires the RPC is used.")
      if (!this.options.rpc)
        this.options.rpc = new JSONRPC();

      if (!this.options.rpcsearchfunction)
      {
        console.error("rpcsearchfunction must be specified when fields_rpc is used.");
        return;
      }

      // Copy the filter fields which must go through the RPC
      let fields = {};
      for (let key of this.options.fields_rpc)
        fields[key] = this.filters[key];

      // If the page passed the "filteredoverview_folderurl" we can use that
      // to pass to the RPC in case restrict_url needs to be used on the Consilio.
      // This makes searches quicker

      let sourceurl = whintegration.config.obj.filteredoverview_folderurl;
      if (!sourceurl)
        sourceurl = (document.location.protocol + "//" + document.location.host + document.location.pathname);

      if (this.options.debug_rpc)
      {
        console.info("Calling RPC", { rpcfunc:   this.options.rpcsearchfunction
                                    , fields:    fields
                                    , sourceurl: sourceurl
                                    });
      }

      // await this.rpc.async('SearchWords', words);
      let searchresults = await this.options.rpc.promiseRequest(this.options.rpcsearchfunction
                , [ fields
                  , sourceurl
                  ]
                );
      if (this.options.debug_rpc)
        console.info("[FO] Result for rpc FindProjects", searchresults);

      this.rpc_matchesids = searchresults.matchesids;
    }
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    if (this.options.debug)
      console.log("[filteredoverview] refreshResultsByFiltering", this.filters);


    // First pass, determine the matches
    for(let item of this.items)
    {
      let ismatch = this.isMatch(item.filterdata, this.filters);

      if (this.options.debug_matching)
        console.log(ismatch, item);

      item.node.classList.toggle("notmatching", !ismatch);

      if(ismatch)
        ++totalfound;
    }


    this.resultscount = totalfound;


    // FIXME: implement a cleaner way, container as option?
    // let container = this.items[0].node.parentNode;
    let container = this.options.node_results;


    if (this.filters.sort)
    {
      if (this.options.sortorderings)
      {
        let orderids = this.options.sortorderings[this.filters.sort];

        if (this.options.debug_ordering)
        {
          console.log("Sort by", this.filters.sort);
          console.log("Sort ordering ids", orderids);
        }

//while(container.firstChild)
//  container.removeChild(container.firstChild);

        for(let id of orderids)
        {
          let found = false;

          // Find the item with that ID (FIXME:This should be done upon initialization instead of here....)
          for (let item of this.items)
          {
inner:
            if (item.filterdata.id == id)
            {
              container.appendChild(item.node);
              found = true;
              break inner;
            }
          }

          if (!found)
            console.error("Didn't find #"+id);
        }
      }
      else
        console.error("Please add sortorderings field to FilteredOverview options.");
      //this.doSortItems(this.filters.sort);
    }

    this.setFeedback(totalfound);
  }


  onSetPage(page, evt)
  {
    // console.log("Set page", page);
    this.currentpage = page;

    if (this.pagination_by_rpc)
    {
      this.refreshResults(true);
      // FIXME: how should we handle focus with RPC refreshes?
      //        maybe move the focus only if the user didn't change focus between the using the page navigation and receiving the result?
    }
    else
    {
      this.refreshPagination();
      this.scrollResultsIntoView();

      let focusable_nodes = domfocus.getFocusableComponents(this.options.node_results);
      if (focusable_nodes.length > 0)
      {
        // NOTE: switching contentEditable is a hack to trigger the :focus-within
        //       so when using keyboard navigation on the pagination bar
        //       we can show the focus has moved to the first result.
        //       See https://stackoverflow.com/questions/69281522/apply-focus-visible-when-focusing-element-programatically
        // FIXME: .contentEditable hack not verified on all browsers
        // FIXME: when whether .contentEditable switching kills eventListeners
        // FIXME: maybe temporary setting a temporary focus-within classname and remove it upon losing focus is better
        // NOTE:  discussion on having an focus() option for this: https://discourse.wicg.io/t/ability-to-explicitly-set-focus-visible-from-focus/5695
        focusable_nodes[0].contentEditable = true;
        focusable_nodes[0].focus();
        focusable_nodes[0].contentEditable = false;
      }
    }
  }


  // handle clientside pagination
  refreshPagination()
  {
    if (this.resultsperpage == 0) // pagination enabled for this overview?
      return;

    this.form.classList.add("filteredoverview--paginated");

    let visibleitemnr = 0;
    for(let item of this.options.node_results.children)
    {
      if (item.classList.contains("notmatching"))
      {
        item.classList.remove("notcurrentpage");
        continue;
      }

      item.classList.toggle("notcurrentpage", Math.floor(visibleitemnr / this.options.resultsperpage) != this.currentpage);
      visibleitemnr++;
    }

    // console.info("***PAGINATION", this.resultscount, this.resultsperpage, this.currentpage);
    this.updatePagination(this.resultscount, this.resultsperpage, this.currentpage);
  }

  updatePagination(totalfound, resultsperpage, currentpage)
  {
    if (this.options.debug)
      console.info("updatePagination total: %i, resultsperpage: %i, currentpage: %i", totalfound, resultsperpage, currentpage);

    if (this.options.node_pagination)
    {
      let pagefocusnr = null;
      if (this.options.node_pagination.contains(document.activeElement)) // pagination bar contains the focus
      {
        // let pagebutton =
        // ADDME: store page which has focus so we can restore after the pagebutton DOM has been rewritten
      }

      // this.options.node_pagination.innerHTML = "";
      let paginationdom = pagination.createPaginationBar(
                                  this.options.node_pagination
                                , { itemCount:        totalfound
                                  , itemsPerPage:     resultsperpage
                                  , currentPage:      currentpage
                                  }
                                , { gotoPageCallback: this.onSetPage.bind(this)
                                  , controls:         this.options.node_results
                                  });
      //this.options.node_pagination.appendChild(paginationdom);
    }
    else
      console.error("No options.node_pagination specified.");
  }



/*
  doSortItems(sortby)
  {
    switch(sortby)
    {
      // from cheap to expensive
      case "oldest":
        this.items.sort(function(a,b) { return a.filterdata.published > b.filterdata.published ? 1 : -1; });
        break;

      // large to small
      case "newest":
        this.items.sort(function(a,b) { return a.filterdata.published < b.filterdata.published ? 1 : -1; });
        break;

      case "lastupdated":
        this.items.sort(function(a,b) { return a.filterdata.modified < b.filterdata.modified ? 1 : -1; });
        break;

      default:
        alert("unknown sort method.");
        break;
    }

    // FIXME: implement a cleaner way, continer as option?
    let container = this.items[0].node.parentNode;
    //console.log("Items container", container);
    for (let item of this.items)
      container.appendChild(item.node);
  }
*/

  isTextualFieldMatchIn(findtext, fulltext)
  {
    let matchparts = findtext.toLowerCase().split(" ");

    for (let part of matchparts)
    {
      part = part.trim();
      let ismatch = part != "" && fulltext.toLowerCase().indexOf(part) > -1;

      if (ismatch)
        return ismatch;
    }

    return false;
  }


  isMatch(itemfilterdata)
  {
    let doesnotmatch = false;


    for(let key in this.filters)
    {
      if (this.options.fields_notforfiltering.indexOf(key) > -1)
        continue;

      let filtervalue = this.filters[key];

      // console.log("filtervalue", filtervalue);

      if (this.options.fields_rpc.indexOf(key) > -1)
      {
        // console.log("handing rpc field", key);

        if (this.rpc_matchesids.indexOf(itemfilterdata.id) == -1)
        {
          // console.log(itemfilterdata.id, "isn't in the set of matches"); // this.rpc_matchesids
          doesnotmatch = true;
        }

        continue;
      }


      let itemvalue = itemfilterdata[key];


      if(key in this.options.filtermatchcallbacks)
      {
        // We got a custom handling for this field
        return this.options.filtermatchcallbacks[key](filtervalue, itemvalue);
      }

      // We have to do the match ourselves. This can only be done if this field also exists in the itemfilterdata.
      if (!(key in itemfilterdata))
      {
        console.log("Item data missing field '"+key+"'");
        continue;
      }


      if (this.options.fields_textmatch.indexOf(key) > -1)
      {
        let ismatch = this.isTextualFieldMatchIn(filtervalue, itemvalue)
        doesnotmatch = !ismatch;
      }
      else if(Array.isArray(filtervalue) && Array.isArray(itemvalue)) // Find multiple values in multiple values
      {
        let found = false;

        if (itemvalue.length > 0)
        {
          let numeric = typeof itemvalue[0] == "number"; // is the expected value numeric?
          // console.log(key, "is numeric", numeric);
          for( let i = 0; i < filtervalue.length; ++i )
          {
            let single_filter_value = numeric ? parseInt(filtervalue[i]) : filtervalue[i];
            if (itemvalue.indexOf(single_filter_value) > -1)
            {
              found = true; // At least one tag matched
              break;
            }
          }
        }

        if (!found)
        {
          doesnotmatch = true;
          // console.log("not matching on", key, "value", itemvalue, "not found in", filtervalue);
        }
      }
      else if(Array.isArray(itemvalue))
      {
        // lookup single value (pulldown or text) in array
        if (itemvalue.indexOf(filtervalue) == -1 )
        {
          doesnotmatch = true;
          // console.log("not matching on", key, "value", itemvalue, "not found in", filtervalue);
        }
      }
      else if(Array.isArray(filtervalue))
      {
        if (filtervalue.indexOf(itemvalue) == -1)
        {
          doesnotmatch = true;
          // console.log("not matching on", key, "value", itemvalue, "not found in", filtervalue);
        }
      }
      else
      {
        // lookup single value (pulldown or text) in a non-array (number of string)
        // (we assume the filter contains a string because a pulldown doesn't know integers)
        let filtervalue_cast = (typeof itemvalue == "number" ? parseInt(filtervalue) : filtervalue);
        //console.info("match", matchvalue, typeof(matchvalue));
        if (itemvalue != filtervalue_cast)
        {
          doesnotmatch = true;
          // console.log("not matching on", key, "value", itemvalue, "not found in", filtervalue_cast);
        }
      }
    }

    if (this.options.finalize_itemmatch)
    {
      if (!this.options.finalize_itemmatch(itemfilterdata))
      {
        console.info("match disabled by finalize_itemmatch");
        return false;
      }
    }

    return !doesnotmatch;
  }




  async __refreshShared(interactivechange)
  {
    let filters = this.getFiltersFromForm();
    this.filters = filters.filters;
    this.filters_titles = filters.filters_titles;
    this.tags = filters.tags;


    // Store the form values
    if (this.options.statestorageid)
      sessionStorage[this.options.statestorageid] = JSON.stringify(this.filters);


    // Give a change to manipulate the filters (or for method: "custom" to refresh/filter the list)
    if (this.options.onfilterchange)
      this.options.onfilterchange(this.filters);

    this.doTracking(interactivechange);

    //console.log("refreshResults()", this.filters);

    this.syncFilterTags();

    this.updateURL(filters.filters);
  }

  async refreshResultsFromRPC(evt)
  {
    let interactivechange = !!evt;
    this.__refreshShared(interactivechange);

    if (this.options.debug)
      console.log("this.filters", this.filters);

    let rpcfilters = this.filters;
    // console.log("Calling", this.options.rpcsearchfunction, rpcfilters);

    // NOTE: The RPC can choose to ignore the "resultsperpage" setting
    //       and override it. (by returning another value in the response)
    if (this.options.resultsperpage)
    {
      rpcfilters = { ...rpcfilters
                   , page:           this.currentpage
                   , resultsperpage: this.options.resultsperpage
                   };
    }

    // Callback. Usefull for example for BOOLEAN options (since the FilteredOverview doesn't internally have a BOOLEAN type)
    if (this.options.getfiltersforrpc)
      rpcfilters = this.options.getfiltersforrpc(this.filters);

    if (this.options.debug_rpc)
      console.info("[FilteredOverview] Calling", this.options.rpcsearchfunction, "with", rpcfilters);

    let results = await this.options.rpc.async(this.options.rpcsearchfunction, rpcfilters);

    if (this.options.debug_rpc)
      console.info("[FilteredOverview] RPC response", results);
    // console.log(results);

    if (this.options.ongotrpcresults)
      this.options.ongotrpcresults(results);

    this.options.node_results.innerHTML = results.resultshtml;

    this.setFeedback(results.totalcount);

    //console.log("Update resultscount to", results.totalcount, "****");
    this.resultscount = results.totalcount;

// FIXME: do pagination before of after onafterrefresh ??
// before - we can hide items an onafterrefresh can measure dimensions of results??

    // FIXME: pagination support for serverside pagination not tested yet
    if (   "page" in results
        && "resultsperpage" in results) // server/RPC is opting into the pagination
    {
      // console.warn("Pagination for RPC not tested yet.");
      this.pagination_by_rpc = true;
      this.currentpage = results.page;
      this.resultsperpage = results.resultsperpage;
    }

    this.onAfterRefreshResults();
  }


  ////////////////////////////////////////////////////////////////////////////////////////////
  //
  //  Tags
  //

  syncFilterTags()
  {
    if (this.options.debug_tags)
      console.log("[filteredoverview] syncFilterTags", this.tags);


    let use_tags = this.tags;
    if (this.options.tags_remap)
      use_tags = this.options.tags_remap([...this.tags]); // pass a clone of the tags object


    let filtertagscontainer = document.querySelector(".filtertags__items");
    if (!filtertagscontainer)
      return;

    let tagcontainer = document.createDocumentFragment();

    // Just generate ALL tags
    for (let tag of use_tags)
    {
      if (this.options.fields_hidetag.indexOf(tag.name) > -1)
      {
        continue; // we don't want any tags generated for this field
      }

      let item = document.createElement("button");
      item.setAttribute("type", "button");
      item.setAttribute("aria-label", tag.title + " " + getTid("acoi:webdesigns.site.js.filteredoverview.filtertag-remove-suffix"));
      item.__tag = tag; // reference the object so upon using the button we can quickly lookup which value(s) need to be unchecked/cleared

      item.className = "filtertags__item";

      if (tag.color)
      {
        item.style.borderColor = tag.color;
      }

      let title = document.createElement("div");
      title.className = "filtertags__item__title";
      title.textContent = tag.title;

// Seperate button so
// - we can decide to make the other part of the button
      // let removebutton = document.createElement("")

      item.appendChild(title);
      tagcontainer.appendChild(item);
    }

    filtertagscontainer.innerHTML = "";
    filtertagscontainer.appendChild(tagcontainer);

/*
    // FIXME: type="reset" so screenreader can announce it? might currently cause another extra reset event though.
    let resetfilterbutton = <button class="filteredoverview-action--resetfilters">Clear all filters</button>;
    resetfilterbutton.addEventListener("click", evt => this.doClearFilters(evt));
    filtertagscontainer.appendChild(resetfilterbutton);
*/
  }

  doClearFilters(evt)
  {
    /*
    this.filters = {};
    this.prefillFormWithFilterValues(this.filters);
    */
    // evt.preventDefault();
    this.resetFilters(evt);
  }



  doCheckForTagRemoval(evt)
  {
    let tagnode = dompack.closest(evt.target, ".filtertags__item");
    if (!tagnode)
      return;

    evt.preventDefault();

    if (this.options.onremovetag)
    {
      if (this.options.onremovetag(tagnode.__tag))
      {
        this.refreshResults(true);
        return;
      }
    }

    let node = tagnode.__tag.node; // get the form field node

    if (!node)
    {
      console.error("Failed to find field/option associated with the tag");
      return;
    }

    this.__resetFormNodeValue(node);

    this.refreshResults(true);
  }

  __resetFormNodeValue(node)
  {
    let defaultval = this.options.defaultfilters[node.name];

    if (node.tagName == "SELECT")
    {
      if (defaultval)
        node.value = defaultval;
      else
      {
        let firstitem = node.querySelector("option");
        if (firstitem)
          node.value = firstitem.value;
      }
      // node.value = "";
    }
    else if (node.tagName == "INPUT" && ["checkbox", "radio"].indexOf(node.getAttribute("type")) > -1)
      node.checked = defaultval ?? false;
    // else if (node.tagName == "INPUT" && ["input", "search"].indexOf(node.getAttribute("type")) > -1)
    else if (node.tagName == "INPUT") // assume anothing other is textual
      node.value = defaultval ?? "";
  }



  ////////////////////////////////////////////////////////////////////////////////////////////
  //
  //  Misc helper functions
  //

  isFilterTheDefault(filters)
  {
    return object_equals(this.options.defaultfilters, filters);
  }

  /*
  isFilterActive()
  {
    return Object.keys(this.filters).length;
  }
  */

  isFilterActive()
  {
    //return Object.keys(this.filters).length > 0;

    // Lookup if any of the fields in this.filters can be considered "active".
    // (not listed in the fields_dontcountasactive array)
    let keynames = Object.keys(this.filters);

    let active = false;
    for (let keyname of keynames)
    {
      if (this.options.fields_dontcountasactive.indexOf(keyname) == -1
          && this.options.defaultfilters[keyname] != this.filters[keyname]
         )
      {
        // console.log("active because of", keyname);
        active = true;
        break;
      }
    }

    return active;
  }

  getUrlParam(name)
  {
    var urlparamstr = location.search.replace(/\+/g,"%20");
    if(name=(new RegExp('[?&]'+encodeURIComponent(name)+'=([^&]*)')).exec(urlparamstr))
      return decodeURIComponent(name[1]);
    return "";
  }

  setFeedback( totalfound, totalshown )
  {
    let filteractive = this.isFilterActive();

    document.documentElement.classList[ filteractive ? "add" : "remove"]("filteredoverview--filtersactive");

    document.documentElement.classList[ totalfound == 0 ? "add" : "remove"]("filteredoverview--noresults");
    document.documentElement.classList[ totalfound == 1 ? "add" : "remove"]("filteredoverview--singleresult");
    document.documentElement.classList[ totalfound  > 1 ? "add" : "remove"]("filteredoverview--multipleresults");
/*
    if (this.feedbacknode)
    {
      if (filteractive)
      {
        if (totalfound == 0)
          this.feedbacknode.innerText = getTid("acoi:webdesigns.site.js.filteredoverview.results-none");
        else if (totalfound == 1)
          this.feedbacknode.innerText = getTid("acoi:webdesigns.site.js.filteredoverview.results-single");
        else if (totalfound > 1)
          this.feedbacknode.innerText = getTid("acoi:webdesigns.site.js.filteredoverview.results-multiple", totalfound);
      }
      else
      {
        if (totalfound == 0)
          this.feedbacknode.innerText = getTid("acoi:webdesigns.site.js.filteredoverview.results-nofilter-none");
        else if (totalfound == 1)
          this.feedbacknode.innerText = getTid("acoi:webdesigns.site.js.filteredoverview.results-nofilter-single");
        else if (totalfound > 1)
          this.feedbacknode.innerText = getTid("acoi:webdesigns.site.js.filteredoverview.results-nofilter-multiple", totalfound);
      }
    }
*/
    if (this.feedbacknode)
    {
      if (filteractive && this.options.showsummary)
      {
        if (totalfound == 0)
          this.feedbacknode.innerHTML = getHTMLTid("acoi:webdesigns.site.js.filteredoverview.results-none");
        else if (totalfound == 1)
          this.feedbacknode.innerHTML = getHTMLTid("acoi:webdesigns.site.js.filteredoverview.results-single");
        else if (totalfound > 1)
          this.feedbacknode.innerHTML = getHTMLTid("acoi:webdesigns.site.js.filteredoverview.results-multiple", totalfound);
      }
      else
      {
        if (totalfound == 0)
          this.feedbacknode.innerHTML = getHTMLTid("acoi:webdesigns.site.js.filteredoverview.results-nofilter-none");
        else if (totalfound == 1)
          this.feedbacknode.innerHTML = getHTMLTid("acoi:webdesigns.site.js.filteredoverview.results-nofilter-single");
        else if (totalfound > 1)
          this.feedbacknode.innerHTML = getHTMLTid("acoi:webdesigns.site.js.filteredoverview.results-nofilter-multiple", totalfound);
      }
    }

    this.setTeaserResultCount(totalfound, totalshown);
  }


  getURLForFilters(filters)
  {
    if (this.isFilterTheDefault(filters))
    {
      // console.info("Filters default");
      return window.location.pathname;
    }

    // console.info("Filters NOT default");
    return getURLWithRecordApplied(filters, Object.keys(this.options.hiddenfilters));
  }
}


function object_equals( x, y ) {
  if ( x === y ) return true;
    // if both x and y are null or undefined and exactly the same

  if ( ! ( x instanceof Object ) || ! ( y instanceof Object ) ) return false;
    // if they are not strictly equal, they both need to be Objects

  if ( x.constructor !== y.constructor ) return false;
    // they must have the exact same prototype chain, the closest we can do is
    // test there constructor.

  for ( var p in x ) {
    if ( ! x.hasOwnProperty( p ) ) continue;
      // other properties were tested using x.constructor === y.constructor

    if ( ! y.hasOwnProperty( p ) ) return false;
      // allows to compare x[ p ] and y[ p ] when set to undefined

    if ( x[ p ] === y[ p ] ) continue;
      // if they have the same strict value or identity then they are equal

    if ( typeof( x[ p ] ) !== "object" ) return false;
      // Numbers, Strings, Functions, Booleans must be strictly equal

    if ( ! object_equals( x[ p ],  y[ p ] ) ) return false;
      // Objects and Arrays must be tested recursively
  }

  for ( p in y )
    if ( y.hasOwnProperty( p ) && ! x.hasOwnProperty( p ) )
      return false;
        // allows x[ p ] to be set to undefined

  return true;
}


function getURLWithRecordApplied(filters, ignorefields)
{
  let urlparams = [];
  for(let name of Object.keys(filters))
  {
    if (ignorefields.includes(name))
      continue;

    let val = filters[name];

    if(["number", "string"].indexOf(typeof val) > -1)
    {
      if (val != "")
        urlparams.push(name + "=" + encodeURIComponent(val));
    }
    else if(typeof val == "boolean")
    {
      if (val)
        urlparams.push(name);
    }
    else if (Array.isArray(val))
    {
      let encodedvals = [];
      for(let valitem of val)
        encodedvals.push(encodeURIComponent(valitem));

      if (val.length > 0)
        urlparams.push( name + "=" + encodedvals.join(","));
    }
  }

  window.enabled_sitedebug = location.href.indexOf("debug") > -1;
  if (window.enabled_sitedebug)
    urlparams.push("debug");

  let url = "";
  if (urlparams.length > 0)
    url = window.location.pathname + "?" + urlparams.join("&");
  else
    url = window.location.pathname; // set absolute path so we remove the "?"

  //url += location.hash; // keep hash on the URL

  return url;
}
