import JSONRPC from "@mod-system/js/net/jsonrpc";
import FilteredOverview from "@mod-acoi/webdesigns/shared/components/filteredoverview";
import getTid from "@mod-tollium/js/gettid";
import * as dompack from "dompack";


// dompack.onDomReady(initResourcesPage);
dompack.register(".eventsoverview-filters", initEventsOverview);


function initEventsOverview(formnode)
{
  console.log("initEventsOverview", formnode);

  let resultsnode = document.getElementById(".eventsoverview-searchresults");

  let settings =
          { trackingid:             "eventsoverview" // name for analytics (and debug)
          , statestorageid:         "eventsoverview"
          , debug:                  true

         // DOM
          , node_results:           resultsnode
          , resultsanchor:          document.getElementById("eventsoverview-searchresults")
          , items:                  document.querySelectorAll(".searchresult")
          , getfloatingteasertext:  getFloatingTeaserText

         // UI behaviour
          , showsummary:            false

          , method:                 "clientside"
          //, rpc:                    new JSONRPC({ url: "/wh_services/acoi/news/" })
          //, rpcsearchfunction:      "GetNews"
          //, fields_rpc:             ["query"]


          /*
          , onexpandfilters:   onExpandFilters
          , oncollapsefilters: onCollapseFilters
          */
          };

  window.filteredoverview_events = new FilteredOverview(formnode, settings);
  window.filteredoverview_events.refreshResults();
}

/*
function onExpandFilters()
{
  let dialogfrag = document.getElementById("dialogtemplate").content.cloneNode(true);
  let dialog = dialogfrag.querySelector("dialog");
  let dialog_contentnode = dialogfrag.querySelector(".dialog__content");
  // let form = dialogfrag.querySelector("form");
  // console.log(dialogfrag.outerXML);

  dialog.classList.add("eventsoverview-filtersdialog");

  let content_to_import = document.getElementById("eventsoverviewpage_dialog_mobile_filters").content.cloneNode(true);
  let form = content_to_import.querySelector("form");
  form.addEventListener("change", doUpdateFilters);

  form.addEventListener("reset", evt => doClearFilters(evt));


  console.info(window.filteredoverview_events.filters);

  form.type.value = window.filteredoverview_events.filters.type;
  // form.type.value = window.filteredoverview_events.getFiltersFromForm().type;

  let teaserbar = document.querySelector(".filteredoverview__teaser");
  dialog_contentnode.appendChild(content_to_import);
  dialog_contentnode.appendChild(teaserbar);
  document.body.appendChild(dialog);

  if (dialog.showModal)
    dialog.showModal();
  else
    dialog.setAttribute("open", "");
}

function onCollapseFilters()
{
  let dialog = document.querySelector(".eventsoverview-filtersdialog");
  if (dialog.showModal)
    dialog.close();
  else
    dialog.removeAttribute("open");

  // Return the teaserbar to the <body> element
  let teaserbar = document.querySelector(".filteredoverview__teaser");
  document.body.appendChild(teaserbar);

  dialog.remove();
}
*/

function doClearFilters()
{
  window.filteredoverview_events.resetFilters();
}


function doUpdateFilters(evt)
{
  let form = evt.target.closest("form");

  //console.log("Setting the type to", form.type.value);

  /* Get the filters from this form and apply them to the FilteredOverview */
  // window.filteredoverview_events.prefillFormWithFilterValues({ type: form.type.value });
  window.filteredoverview_events.setFilters({ type: form.type.value });

}

function getFloatingTeaserText(totalfound, totalshown)
{
  let txt = _getFloatingTeaserText(totalfound, totalshown);
  return txt;
}

function _getFloatingTeaserText(totalfound, totalshown)
{
  if( totalfound == 1 )
    return getTid('acoi:webdesigns.site.js.filteredoverview.tease-result', totalfound);
  else if( totalfound > 1 )
    return getTid('acoi:webdesigns.site.js.filteredoverview.tease-results', totalfound);
  else
    return getTid("acoi:webdesigns.site.js.filteredoverview.tease-noresults");
}
