import "./publications-filters.scss";
import "./publications-listview.scss";
import "./landing.scss";
import JSONRPC from "@mod-system/js/net/jsonrpc";
import FilteredOverview from "@mod-acoi/webdesigns/shared/components/filteredoverview";
import getTid from "@mod-tollium/js/gettid";
import * as dompack from "dompack";
import * as whintegration from "@mod-system/js/wh/integration";


let landing_visible = true;
let publicationoverview_form = null;
let publicationoverview_resultscontainer = null;

dompack.register(".publicationsoverview-filters", initPublicationsOverview);

window.addEventListener("click", doCheckForPublicationsToggle);


function initPublicationsOverview(formnode)
{
  //console.log("initPublicationsOverview", formnode);

  publicationoverview_form = formnode;

  publicationoverview_resultscontainer = document.getElementById("publicationsoverview-searchresults");

  let settings =
          { trackingid:             "publicationsoverview" // name for analytics (and debug)
          , statestorageid:         "publicationsoverview" // for persisting filter settings (restoring them when returning to the page in the same session)

          , node_results:           publicationoverview_resultscontainer
          , node_pagination:        document.querySelector(".acoifilterpage__pagination")
          , resultsanchor:          document.querySelector(".acoifilterpage") // have both the filterbar (sort + X results) and searchresults in view
          , items:                  document.querySelectorAll(".searchresult")
          , getfloatingteasertext:  getFloatingTeaserText

          , method:                 "rpc" // retrieve results (as HTML) for each filter action
          , rpc:                    new JSONRPC({ url: "/wh_services/acoi/rpc/" })
          , rpcsearchfunction:      "SearchPublications"

          , resultsperpage:         6
/*
          , defaultfilters:         { periode: "recent"
                                    , datumvan: "publicatie"
                                    }
*/
          , onfilterchange:         onFilterChange
          , ongotrpcresults:        onGotRPCResults
          , onafterrefresh:         onAfterRefresh
          , onremovetag:            doHandleTagRemoval

          , tags_remap:             doRemapTags
          , fields_hidetag:         ["sorteer"]
          , fields_dontcountasactive: ["sorteer"]

          , onexpandfilters:        onExpandFilters
          , oncollapsefilters:      onCollapseFilters
          , additionalform:         document.querySelector(".pageheader__searchpanel form")

          , debug:                  false
          , debug_rpc:              false
          };

  window.filteredoverview_publications = new FilteredOverview(formnode, settings);


  // Landing page buttons
  document.querySelector(".page--publications__showall").addEventListener("click", showAllPublicationTypes);
  document.querySelector(".publicationlanding__categories").addEventListener("click", checkForPublicationTypeSelection);

  // Overview page buttons
  document.getElementById("showallofcategorybutton").addEventListener("click", doRemoveDateFilters);
  document.querySelector(".acoifilterpage__backlink").addEventListener("click", doReturnToPublicationsLanding);


  if (window.filteredoverview_publications.isFilterActive())
    startOverview();
  else
  {
    // since we are using the default filters we can use the publications statically published into the page
    window.filteredoverview_publications.setFeedback(whintegration.config.obj.publicationsoverview.resultscount);
    enrichPublicationDetailsPageLinks();
  }
}


function doHandleTagRemoval(tag)
{
  if (tag.name == "periode")
  {
    publicationoverview_form.periode.value = ""; // selecteer "Alle"
    return true; // report we handled the resetting of the associated form field
  }
}



function onGotRPCResults(results)
{
  // console.warn("onGotRPCResults");
  // console.info(results);

  for (let type of results.types)
  {
    let id = "publicationtype" + type.id;
    let optioninput = document.getElementById(id);

    if (!optioninput)
    {
      if (type.usage > 0)
        console.error("Missing option");

      // We leave out options for which we have no publications (at all) so no problem, go to the next option
      continue;
    }

    let findlabel = `label[for="${id}"] .optionmatches`;
    let counter = document.querySelector(findlabel);
    counter.innerText = `(${type.usage})`;
  }

  publicationoverview_resultscontainer.classList.toggle("acoifilterpage__results--withtoggles", results.anywithdocuments);
  publicationoverview_resultscontainer.classList.toggle("textualsearch", results.textualsearch);
}



function checkForPublicationTypeSelection(evt)
{
  let item = evt.target.closest("[data-preselecttype]");
  if (!item)
    return;

  let type = item.getAttribute("data-preselecttype");


  for (let cbnode of publicationoverview_form.type)
    cbnode.checked = cbnode.value == type;

  console.log(type);

  startOverview();
}


function showAllPublicationTypes()
{
  startOverview();
}

function startOverview()
{
  window.filteredoverview_publications.refreshResults();
  switchToOverview();
}

function onAfterRefresh()
{
  enrichPublicationDetailsPageLinks();

  // If an search/refresh was done (probably though using the text search bar)
  // we must switch to the overview
  if (landing_visible)
    switchToOverview();
}

function switchToOverview()
{
  document.documentElement.classList.add("page--publicationsoverview-hidelanding");
  landing_visible = false;
  document.scrollingElement.scrollTo(0,0);
}

function doReturnToPublicationsLanding()
{
  document.documentElement.classList.remove("page--publicationsoverview-hidelanding");
  landing_visible = true;
  document.scrollingElement.scrollTo(0,0);
}



function doRemoveDateFilters()
{
  let filters = window.filteredoverview_publications.filters;
  /*
  filters.periode = "";
  window.filteredoverview_publications.setFilters(filters);
  */
  window.filteredoverview_publications.setFilters({ periode: "" });
}


function enrichPublicationDetailsPageLinks()
{
  let resultsnode = document.getElementById("publicationsoverview-searchresults");
  let items = resultsnode.querySelectorAll("a.publicationitem__link");

  //console.log(whintegration.config.obj.links.publicationdetails)
  // alert(whintegration.config.obj.links.publicationdetails);

  // convert the slug to the full view page
  for(let item of items)
    item.setAttribute("href", whintegration.config.obj.links.publicationdetails + item.getAttribute("href"));
}




function onFilterChange()
{
  if (!window.filteredoverview_publications)
    return; // initialization hasn't finished yet


  let filters = window.filteredoverview_publications.filters;

  // console.log(filters, window.filteredoverview_publications.filters_titles);

  // "Toon alle <category>" knop
  // Toon als er 1 categorie geselecteerd is + een specifieke periode ("recent" of "custom").
  // Bij gebruik van de knop word de periode teruggezet op "Recent" waarbij
  // alles getoond word.
  let catbutton = document.getElementById("showallofcategorybutton");
  if ("type" in filters && filters.type.length == 1 && filters.periode != "")
  {
    let title = window.filteredoverview_publications.filters_titles.type[0];


    // Find record for the "type" field
    let typefilterrec = null;
    for (let tag of window.filteredoverview_publications.tags)
    {
      if (tag.name == "type")
      {
        typefilterrec = tag;
        break;
      }
    }


    catbutton.style.display = "";
    catbutton.innerText = `Toon alle ${typefilterrec.node.dataset.titlePlural}`;
  }
  else
  {
    catbutton.style.display = "none";
  }



  // Update UI to hide/show fields which are only relevant to a custom period
  let customperiod = window.filteredoverview_publications.filters.periode == "custom";
  document.querySelector(".acoifilterpage").classList.toggle("publications--filtercustomperiod", customperiod);


  // Verwijder velden die geen effect hebben voor deze zoekopdracht
  // (de velden die alleen gebruikt worden om op een custom (eigen gekozen) periode te zoeken)
  if (filters.periode != "custom")
  {
    delete filters.datumvan;
    delete filters.vanaf;
    delete filters.tot;
  }
}


function doRemapTags(tags)
{
  let tagsmap = {};

  // Now reconstruct the tags array
  let finaltags = [];

  for (let item of tags)
  {
    if (["periode", "datumvan", "vanaf", "tot"].indexOf(item.name) > -1)
      tagsmap[item.name] = item;
    else
      finaltags.push(item);
  }

  // console.log("Tags converted to map:", tagsmap);

  if (tagsmap.periode)
  {
    if (tagsmap.periode.value == "custom")
    {
      console.info("CUSTOM met datum van", tagsmap.datumvan.value);

      let vanaf = tagsmap.vanaf ? datestringTo(tagsmap.vanaf.value) : "";
      let tot   = tagsmap.tot   ? datestringTo(tagsmap.tot.value)   : "";



      let whentext = "";
      if (vanaf != "" && tot != "")
        whentext = `van: ${vanaf} tot ${tot}`;
      else if (vanaf != "")
        whentext = `vanaf: ${vanaf}`;
      else if (tot != "")
        whentext = `tot: ${tot}`;


      if (tagsmap.datumvan.value == "publicatie")
        tagsmap.periode.title = `Datum publicatie ${whentext}`;
      else if (tagsmap.datumvan.value == "wijziging")
        tagsmap.periode.title = `Datum laatste wijziging van publicatie ${whentext}`;
      // else if (tagsmap.datumvan.value == "document")
      //  tagsmap.periode.title = `Datum document ${whentext}`;

      finaltags.push(tagsmap.periode);
    }
    else
    {
      tagsmap.periode.title = "Datum publicatie: meest recent";
      finaltags.push(tagsmap.periode);
    }
  }

  // Readd fields which we don't handle here

  // don't add start and end date to the tags,
  // they were combined into the "period" tag.

  // console.log("New tags list:", finaltags);

  return finaltags;
}


function doCheckForPublicationsToggle(evt)
{
  let toggle = evt.target.closest(".publicationitem__toggle");
  if (!toggle)
    toggle = evt.target.closest(".publicationitem__doccount_and_toggle");

  if (!toggle)
    return;

  evt.preventDefault();

  let panelid = toggle.getAttribute("aria-controls");
  let panelnode = document.getElementById(panelid);

  let itemnode = evt.target.closest(".publicationitem");

  let show = !!panelnode.hasAttribute("hidden");

  console.info("Panelnode", panelid, panelnode,  show ? "true" : "false");


  let all_toggles = itemnode.querySelectorAll(".publicationitem__toggle, .publicationitem__doccount_and_toggle");
  for(let toggle of all_toggles)
    toggle.setAttribute("aria-expanded", show ? "true" : "false");

  if (show)
    panelnode.removeAttribute("hidden");
  else
    panelnode.setAttribute("hidden", "until-found");

  itemnode.classList.toggle("publicationitem--showdocuments", show);
}


// open mobile fullscreen filters
function onExpandFilters()
{
  let dialogfrag = document.getElementById("dialogtemplate").content.cloneNode(true);
  let dialog = dialogfrag.querySelector("dialog");
  let dialog_contentnode = dialogfrag.querySelector(".dialog__content");

  dialog.classList.add("publicationsoverview-filtersdialog");

  // Move the filters into the modal dialog
  let filtersform = document.querySelector(".publicationsoverview-filters");
  dialog_contentnode.appendChild(filtersform);

  // Move the teaserbar into the modal dialog
  // (otherwise it won't able to overlap the <dialog> because it's on the browser's toplayer)
  let teaserbar = document.querySelector(".filteredoverview__teaser");
  dialog_contentnode.appendChild(teaserbar);

  document.body.appendChild(dialog);

  if (dialog.showModal)
    dialog.showModal();
  else
    dialog.setAttribute("open", "");
}


function onCollapseFilters()
{
  // Return the filters back from the modal dialog to it's initial position
  // in the .acoifilterpage container.
  let filtersform = document.querySelector(".publicationsoverview-filters");
  let acoifilterpage = document.querySelector(".acoifilterpage");
  acoifilterpage.insertBefore(filtersform, acoifilterpage.firstChild);

  // Return the teaserbar to the <body> element
  let teaserbar = document.querySelector(".filteredoverview__teaser");
  document.body.appendChild(teaserbar);

  let dialog = document.querySelector(".publicationsoverview-filtersdialog");
  if (dialog.showModal)
    dialog.close();
  else
    dialog.removeAttribute("open");

  dialog.remove();
}


function datestringTo(inputvalue)
{
  let parts = inputvalue.split("-");
  return parts[2]+"-"+parts[1]+"-"+parts[0];
}

function getFloatingTeaserText(totalfound, totalshown)
{
  let txt = _getFloatingTeaserText(totalfound, totalshown);
  return txt;
}

function _getFloatingTeaserText(totalfound, totalshown)
{
  if( totalfound == 1 )
    return getTid('acoi:webdesigns.site.js.filteredoverview.tease-result', totalfound);
  else if( totalfound > 1 )
    return getTid('acoi:webdesigns.site.js.filteredoverview.tease-results', totalfound);
  else
    return getTid("acoi:webdesigns.site.js.filteredoverview.tease-noresults");
}
