import "./header-site-base.scss";
import "./header-site.scss";
//import "./header-site-acoi.scss"; - use in site-<webdesign>.scss
//import "./header-menubar.scss";
//import "./header-site-searchpanel.scss"; - use in site-<webdesign>.scss
import "./dialog.scss";

import "@mod-acoi/webdesigns/shared/components/spc-menubar";

import * as dompack from "dompack";



dompack.onDomReady(onDomReady);
window.addEventListener("load", hideMenubarIfOverflowing);

window.addEventListener("resize", onResize);


let searchtoggle;


function onDomReady()
{
  //if (document.documentElement.classList.contains("siteheader2020"))
  //  hideMenubarIfOverflowing();

  let searchpanel = document.querySelector(".header-searchpanel-content");
  if (!searchpanel)
    return;

  searchpanel.addEventListener("keyup", evt => doCheckKeypress(evt));

  searchtoggle = document.querySelector(".header-menubar__showsearchpanel");
  if (searchtoggle)
    searchtoggle.addEventListener("click", evt => doActivateSearchPanel(evt));

  let closebutton = document.querySelector(".header-searchpanel-closebutton");
  closebutton.addEventListener("click", evt => doCloseSearchPanel(evt));
}

function doActivateSearchPanel(evt)
{
  if (!document.documentElement.classList.contains("page--search"))
    setSearchPanelExpanded(true);

  document.getElementById("spc-searchbar__input").focus();
}

function setSearchPanelExpanded(expand)
{
  if (expand)
  {
    document.documentElement.classList.add("searchpanel-active");
    searchtoggle.setAttribute("aria-expanded", "true");
  }
  else
  {
    document.documentElement.classList.remove("searchpanel-active");
    searchtoggle.setAttribute("aria-expanded", "false");
  }
}

function doCloseSearchPanel(evt)
{
  setSearchPanelExpanded(false);
  // FIXME: restore focus?
}

function doCheckKeypress(evt)
{
  if (evt.key == "Escape")
  {
    evt.preventDefault();
    evt.stopPropagation();
    setSearchPanelExpanded(false);
  }
}



function onResize()
{
  hideMenubarIfOverflowing();
}


function hideMenubarIfOverflowing()
{
  let menubar = document.querySelector(".header-menubar");
  let menuitems = document.querySelector(".header-menubar__menubar");
  let aftermenuitems = menuitems.nextElementSibling;

  menubar.classList.remove("header-menubar--hidemenuitems");
  menubar.classList.remove("header-menubar--sitetitle-multiline");

  let menubar_container = document.querySelector(".header-menubar__content");
  let menu_overflow = menuitems.scrollWidth > menuitems.clientWidth;

  /*
  console.log({ mode:            "normal"
              , clientWidth:     menuitems.clientWidth
              , scrollWidth:     menuitems.scrollWidth
              , overflow:        menu_overflow
              });
  */

  if (menu_overflow)
  {
    menubar.classList.add("header-menubar--sitetitle-multiline");
    menu_overflow = menuitems.scrollWidth > menuitems.clientWidth;

    /*
    console.log({ mode:            "compact"
                , clientWidth:     menuitems.clientWidth
                , scrollWidth:     menuitems.scrollWidth
                , overflow:        menu_overflow
                , mb_classes: menubar.classList
                });
    */

    if (!menu_overflow)
    {
      // Making the sitetitle wrap has freed up enough room to make the menubar visible
      menubar.classList.remove("header-menubar--hidemenuitems");
      menubar.classList.add("header-menubar--sitetitle-multiline");
    }
    else
    {
      menubar.classList.add("header-menubar--hidemenuitems");
      menubar.classList.remove("header-menubar--sitetitle-multiline");

      // Since menuitems have now been hidden, we must also check if the sitetitle
      // causes an overflow:
      menu_overflow = menubar_container.scrollWidth > menubar_container.clientWidth;

      /*
      console.log({ mode:            "hide mainmenu"
                  , clientWidth:     menuitems.clientWidth
                  , scrollWidth:     menuitems.scrollWidth
                  , overflow:        menu_overflow
                  , mb_classes: menubar.classList
                  });
      */

      // If we still overflow, the site title must be too long (probably in mobile mode)
      if (menu_overflow) // we need both the menu to be hidden AND the site title to be compact (multiline)
      {
        menubar.classList.add("header-menubar--sitetitle-multiline");

        /*
        console.log({ mode:            "hide mainmenu AND compact"
                    , clientWidth:     menuitems.clientWidth
                    , scrollWidth:     menuitems.scrollWidth
                    , overflow:        menu_overflow
                    , mb_classes: menubar.classList
                    });
        */
      }
    }
  }
}
//window.hideMenubarIfOverflowing = hideMenubarIfOverflowing;


function onScroll(evt)
{
  if (!document.scrollingElement) // probably Internet Explorer or and old iOS (pre 9)
    return;

  // document.documentElement.scrollTop // Chrome
  // document.body.scrollTop // Safari
  let scrolly = document.scrollingElement.scrollTop; // iOS 9+, Edge 14+ and FF/Chrome

  if (scrolly > 80)
    document.documentElement.classList.add("pageheader--fixed");
  else
    document.documentElement.classList.remove("pageheader--fixed");
}


window.addEventListener("scroll", evt => onScroll(evt));
