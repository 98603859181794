import * as whintegration from "@mod-system/js/wh/integration";
import * as dompack from "dompack";

import "./settings/animations.css";

import "../shared/components/skiplinks";
import "../shared/components/spc-navigationpath";
import "../shared/components/spc-sidebar";
import "../shared/components/spc-tagsblock";
import "../shared/components/spc-pagination/pagination.css";


// Behaviour & Polyfills
import { openLinksInNewWindow } from '@mod-publisher/js/linkhandler';
// import Blazy from "./components/blazy"; // Lazy loading of images

/* Polyfill for element.focus({preventScroll:true}) for SF<15 and iOS <15.5
   - https://caniuse.com/mdn-api_htmlelement_focus_options_preventscroll_parameter
   - https://bugs.webkit.org/show_bug.cgi?id=178583
*/
import "../shared/polyfills/focus-options-polyfill.js";


import "./shared/forms";
import "./shared/filters.scss";
import "@mod-acoi/webdesigns/shared/components/spc-sharebar";



import "../shared/components/spc-tabstrip/pagetabs.scss";

import "./components/filterpage-layout.scss";
import "./components/filterpage-filterpanel.scss";


import "./components/autosuggest";
import * as deeplinks from "./components/deeplinks";
//import "./components/ftu-widget-header/ftu-widget-header.css";
import "./components/acoi-tile-newsitem/acoi-tile-newsitem.css";

import tippy from 'tippy.js';

// Very site specific
import "./site-main.scss";
import "./site-print.scss";
import "./header-site";
import "./header-page";
//import "./sidebar";
import "./footer";


// Widgets
import "../shared/widgets/button";
//import "./widgets/cta";
import "./widgets/accessibilitylabel";
import "./widgets/ctablocks";
import "./widgets/divider";
import "./widgets/downloadsandlinks";
import "./widgets/infobutton";
import "./widgets/news";
import "./widgets/quote";
import "./widgets/quicklink-columns"; // CAK

// Widgets / media
import "@mod-acoi/webdesigns/shared/widgets/image";
import "./widgets/video";
import "@mod-acoi/webdesigns/shared/widgets/rovid";

// Widgets / RTD content
import "../shared/widgets/accordion";
import "./widgets/highlight";
import "./widgets/richtext";
import "./widgets/quicklink-columns";
import "./widgets/vacancy";
import "./widgets/videoplayer";


// Pages
import "./pages/home";
import "./pages/news";
import "./pages/profile";
import "./pages/publications";
import "./pages/publication";
import "./pages/search";
import "./pages/spotlerform";
import "./pages/tool";


import "./site.lang.json";

import "../shared/consent/privacysettings/privacysettings.scss";
import * as consent from "../shared/consent";



console.info("whintegration.config", whintegration.config);

if ("consentcookie" in whintegration.config.obj)
  consent.initialize("consent");



// open external links in new window
var ourdomain = (new URL(location.href)).host.toLowerCase();
openLinksInNewWindow(
    { extensions:    [ 'pdf' ]
    , internalhosts: [ ourdomain
                     //, "www.acoi.nl"
                     //, "acoi.nl"
                     ]
    });

//trackDownloads();


window.enabled_sitedebug = location.href.indexOf("debug") > -1;



dompack.onDomReady(function()
  {
    if (window.enabled_sitedebug)
      document.documentElement.classList.add("site--debug");


    // Needed for the Publisher forms (and form test)
    if (document.documentElement.classList.contains("page--formwebtool"))
    {
      // Set class on the <form> so we can style with low specificy,
      // so we can try to prevent overriding the layout of widgets embedded in RTE fields.
      let webtoolform = document.querySelector("form.wh-form");
      webtoolform.classList.add("wh-form--webtoolform");
    }


    let backlink = document.querySelector(".page-backlink");
    if (backlink)
      backlink.addEventListener("click", onClickBacklink);


    tippy('[data-tippy-content]');
/*
  tippy.setDefaultProps(
      { arrow:  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -1 32 16"><path d="M16 0l16 16H0z"></path></svg>' });
*/
  });



function onClickBacklink(evt)
{
  // If the back page was the previous page, use history.back()
  // instead so we return at the same scrollposition with the same
  // filters or pagination idx.
  if (document.referrer != "")
  {
    // Get the path without arguments for comparing
    let url = new URL(document.referrer);
    let referrerpath = url.origin + url.pathname;

    if (evt.target.href == referrerpath)
    {
      evt.preventDefault();
      history.back();
    }
  }
}
