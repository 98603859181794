import * as dompack from 'dompack';
import * as whintegration from '@mod-system/js/wh/integration';
import * as browser from 'dompack/extra/browser';
import * as questiontypes from "./questiontypes.es";
import { reportEvent, reportNewEvent, setupPxl } from './statistics.es';
export { reportEvent } from './statistics.es'; //backwards compat

let thecontroller;
let currentpage = ""; // either a questiongroup, or the results page
let questiondisplaystart;
let currentquestiontitle = "Start";
let currentquestionuid = "start";
const months = [ "januari", "februari", "maart", "april", "mei", "juni", "juli", "augustus", "september", "oktober", "november", "december" ];

/** Formats a date in FormatDatetime's %d %B %Y' format
*/
function formatNLReadableDate(date)
{
  return date.getDate() + " " + months[date.getMonth()] + " " + date.getFullYear();
}

function setConditionResult(elt, result)
{
  let node = dompack.qS(`[data-uid="${elt.uid}"]`) || dompack.qS(`[data-variantkey="${elt.uid}"]`);
  if(!node)
    return console.log(`For ${elt.uid}: ${result}`);
  node.dataset.conditionresult = result;
}

/** Returns the intersection of two arrays (both should not contain duplicates)
*/
export function array_intersection(array_a, array_b)
{
  return array_b.filter(elt => array_a.includes(elt));
}

/** Returns the union of two arrays (both should not contain duplicates)
*/
export function array_union(array_a, array_b)
{
  return array_a.concat(array_b.filter(elt => !array_a.includes(elt)));
}

/** Sorts an array based on the value given by a function on array elements
*/
function funcValueSort(arr, efunc)
{
  return arr.sort((a, b) => { a = efunc(a); b = efunc(a); return a === b ? 0 : a < b ? 1 : -1; });
}

/** For all keys/values in mergefields, replace occurence of '[key]' in text by the value.
*/
function replaceFields(text, mergefields)
{
  Object.entries(mergefields).forEach(([ key, value ]) => text = text.replace(`[${key}]`, value));
  return text;
}


/*
NOTE: This does the same as:

var question = questions[i];
let questionapi = questiontypes.getQuestionClass(question);
let value = questionapi.getValue()
*/
export function getQuestionAnswerValue(questioncontainerid)
{
  var question = document.getElementById(questioncontainerid);
  const type = question.dataset.type;

  var inputs = dompack.qSA(question, "input");
  var checkedinputs = dompack.qSA(question, "input:checked");
  var selectoptions = dompack.qSA(question, "option:checked");
  let qclass = questiontypes.getQuestionClass(question);

  var value = "";

  switch (type)
  {
    case 'date':
      {
        // Make sure the value is in format YYYY-MM-DD, we'll sort on it later
        var dateday = question.querySelector(".date-day").value.padStart(2, "0");
        var datemonth = question.querySelector(".date-month").value.padStart(2, "0");
        var dateyear = question.querySelector(".date-year").value.padStart(4, "0");

        value = dateyear + "-" + datemonth + "-" + dateday;
      } break;
    case 'amount':
    case 'coronageldhulp:postcode':
      {
        value = inputs[0].value;
      } break;
    case 'pulldown':
      {
        value = selectoptions.length ? selectoptions[0].value : "";
      } break;
    case 'checkboxlist':
      {
        value = dompack.qSA(question, "input:checked").map(item => item.value);
      } break;
    case 'radio':
      {
        value = checkedinputs.map(function(item){ return item.value; }); break;
      } break;
    default:
      return qclass.getValue();
  }

  return value;
}


function string_to_slug (str)
{
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to   = "aaaaeeeeiiiioooouuuunc------";
    for (var i=0, l=from.length ; i<l ; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes

    return str;
}

export default class ToolController
{
  constructor(node, _formsettings)
  {
    thecontroller = this;
    this.node = node;

    this.debug = dompack.debugflags["tools-testmode"];
    this.currentanswers = [];
    this.currentpoints = 0;
    this.answeredquestions = [];
    this.formsettings = _formsettings;
    this.state =
        { filtered_questions:       []
        , filtered_measurevariants: []

        , history:                  [] /// History before current question
        , fullhistory:              [] /// History of all questions
        , lastanswer:               null
        , currentquestion:          null
        , questionsleft:            -1
        };

    this.questions = this.formsettings.questions.slice();
    dompack.qSA(".effecttool__question").forEach( (q,idx) => this.questions[idx].node = q);

    this._filterOnConditions();
    this._calcNext();


    setupPxl(this.formsettings);

    dompack.qSA('.effecttool__nextbutton').forEach(el => el.addEventListener('click', evt => this.doNext(evt)));
    dompack.qSA('.effecttool__prevbutton').forEach(el => el.addEventListener('click', evt => history.back()));
    dompack.qSA('.effecttool__startbutton').forEach(el => el.addEventListener('click', evt => this._gotoFirstQuestion(evt)));
    dompack.qSA(node, ".effecttool__againbuttonrow__againbutton").forEach(node => node.addEventListener("click", () => this.restartTool()));

    window.addEventListener("popstate", evt => this._onPopState(evt));
    window.addEventListener("beforeunload", () => this._onBeforeUnload());
  }

  _onBeforeUnload()
  {
    if(currentquestionuid != "results")
    {
      reportNewEvent("Exit", currentquestiontitle, { sync: true });
    }
    reportEvent("exit",
        { ds_question:    currentquestiontitle
        , ds_questionuid: currentquestionuid
        }, { beacon: true });
    return browser.getName()=="ie" ? undefined : null; //dont trigger an unload message. IE requires undefined, safari requires null, others don't care
  }

  restartTool()
  {
    //delay scroll reset until page is ready to appear
    window.addEventListener("beforeunload", () => window.scrollTo(0,0));
    location.reload();
  }

  startTool()
  {
    this.node.addEventListener("click", evt => checkClick(evt));

    if(this.node.querySelector(".effecttool__opening"))
      this.goToQuestionById(this.node.querySelector(".effecttool__opening"));
    else
      this._gotoFirstQuestion();
  }

  _gotoFirstQuestion()
  {
    let firstquestion = this.state.filtered_questions[0];
    if(firstquestion)
      this.goToQuestionById(this.getQuestionIdByNr(firstquestion.qnr));
    else // No relevant questions!
      this.goToQuestionById('results', true);
  }

  getDOBElement()
  {
    return dompack.qSA(this.node,'div[data-grouptitle]').filter(el => el.dataset.grouptitle.toUpperCase().includes('GEBOORTEDATUM'))[0]
           || dompack.qSA(this.node,'div[data-questiontitle]').filter(el => el.dataset.questiontitle.toUpperCase().includes('GEBOORTEDATUM'))[0];
  }

  _onPopState(evt)
  {
    if(currentquestionuid == "results") //at the final page
    {
      location.reload(); //then back will just restart
      return;
    }

    // https://stackoverflow.com/a/52609108/986440 - prevent forward button from working by pushing dummy state
    if( typeof event.state == "object" && event.state && event.state.obsolete !== true)
    {
      history.replaceState({"obsolete":true},""); //replace our state entry with dummy object to detect we need to double-back
      history.pushState(event.state,""); //repushes the actual state, clearing the forwar dbutton
    }
    else if( typeof event.state == "object" && event.state && event.state.obsolete === true)
    {
      history.back(); //skip 'over' the duplicate states
    }
    this._doPrev();
  }



  getToolId()
  {
    return 'defaulttool'; //FIXME whfs object or file tag or .. ?
  }

  getToolResults()
  {
    var result = this._getResults({currentpage});

    return { variants: result.variants.map(variant => variant.id)
           , formid: getFormId()
           , toolid: this.getToolId()
           , answers: result.answers
           };
  }



  getQuestionIdByNr(nr)
  {
    let questionnode = dompack.qSA(this.node, '.effecttool__question')[nr];
    if (!questionnode)
      console.error("Cannot find question", nr);

    return questionnode.id;
  }

  /// Animate is false when starting with this page
  executePageTransition(animate)
  {
    var result = this._getResults({currentpage});

    var newid = "";
    var nextquestiondatauid = "";


    console.info("executePageTransition", result);
    let lastanswer = result.answers[result.answers.length-1];
    if (lastanswer.redirecturl != "")
    {
      // Use a timeout to give events time to be send
      setTimeout(function() { location.href = lastanswer.redirecturl; }, 250);
      return; // don't switch to the next page
    }


    if (result.type == "question") // next up: question
    {
      newid = this.getQuestionIdByNr(result.questionnr);
      if (document.getElementById(newid))
        nextquestiondatauid = document.getElementById(newid).getAttribute("data-uid");
    }
    else // next up: results page
    {
      newid = "results";
      nextquestiondatauid = "results";

      this._displayResults(result);
    }

    this.goToQuestionById(newid, !animate);
  }


  /** @short make the site go to the question with the specified (element)id
      @param question node or id of the question container
      @param firstcall (only used for "results" page when there are no questions???)
  */
  goToQuestionById(question, firstcall)
  {
    if(typeof question == "string")
      question = document.getElementById(question);
    if (!question)
      throw new Error("Invalid page");


    console.log("Open page: ",question);


    let is_opening = question.classList.contains("effecttool__opening");
    let is_question = question.classList.contains("effecttool__question");
    let is_results = question.classList.contains("effecttool__results");


    let prevpage;
    if (currentpage)
    {
      let prevquestion = document.getElementById(currentpage);
      let was_question = prevquestion.classList.contains("effecttool__question");
      prevpage = was_question ? describeQuestionAndAnswer(currentpage) : {};
    }

    let nextpage = is_question ? describeQuestionAndAnswer(question.id) : {}; // FIXME: what about the results page?


    currentpage = question.id;


    document.documentElement.classList.toggle("page-effecttool--isopening", is_opening);
    document.documentElement.classList.toggle("page-effecttool--isquestion", is_question);
    document.documentElement.classList.toggle("page-effecttool--isresults", is_results);


    this.node.classList.toggle("effecttool--isopening", is_opening);
    this.node.classList.toggle("effecttool--isquestion", is_question);
    this.node.classList.toggle("effecttool--isresults", is_results);

    if(is_question && !this.reported_start)
    {
      this.reported_start = true;
      reportNewEvent('Start');
      reportEvent("start");
    }

    // Tracking through Google Analytics
    if(!firstcall)
    {
      let baseurl = location.href.split('?')[0].split('#')[0];
      if(baseurl[-1] != '/')
        baseurl += '/';

      let subtitle = is_question ? question.dataset.questiontitle : is_results ? "Resultaten" : "";
      let trackurl = baseurl + string_to_slug(subtitle);
      if(window._paq)
      {
        //https://developer.matomo.org/guides/spa-tracking
        window._paq.push(['deleteCustomVariables', 'page']);
        window._paq.push(['setCustomUrl', trackurl]);
        window._paq.push(['setDocumentTitle', subtitle || document.title]);
        window._paq.push(['trackPageView']);
      }
    }
    questiondisplaystart = Date.now();

    let result = this._getResults({considercurrentpage:false, currentpage});

    //Must do question counting AFTER updating currentpage!
    var questioncounter = document.querySelector("#questionsleft");
    if (questioncounter)
    {
      var text = '';
      if (result.type == "question") // next up: question
      {
        text = result.questionsleft > 1 ? "Nog maximaal " + result.questionsleft + " vragen"
                                        : "Nog maximaal " + result.questionsleft + " vraag";
      }
      else
      {
        text = "Alle vragen beantwoord";
      }

      questioncounter.textContent = text;

      this.node.dataset.toolNumquestions = result.numquestions;
      this.node.dataset.toolQuestionsleft = result.questionsleft;

      let progressfraction = result.numquestions > 0 && result.questionsleft > 0 ? (result.numquestions - result.questionsleft) / result.numquestions : 1;
      if(dompack.dispatchCustomEvent(this.node, "toolplatform:progress",
              { bubbles: true
              , cancelable: false
              , detail: { numquestions: result.numquestions
                        , questionsleft: result.questionsleft
                        , progressfraction
                        , prevpage: prevpage
                        , nextpage:  { ...nextpage, node: question }
                        , answers:   result.answers
                        //, variants: result.variants
                        , points:    result.points
                        }
              }))
      {
        let progress = dompack.qS("#questionsleftprogress");
        if(progress)
          progress.style.width = (progressfraction * 100) + '%';
      }
    }

    // Tracking through Webhare PXL
    if (is_question)
      reportOnQuestionShown(question.id);

    // Update .effecttool__page--visible
    dompack.qSA(".effecttool__page").forEach(_ => { _.classList.remove("effecttool__page--visible") });
    question.classList.add("effecttool__page--visible");

    question.classList.remove("error");


    // Make sure to have the top of the question in the viewport
    let formcontainer = dompack.qS("#effecttool__pages");
    let formtop = formcontainer.getBoundingClientRect().top;
    if (formtop < 0)
    {
      let scrollElem = document.scrollingElement ? document.scrollingElement : document.documentElement;

      let ypos = -scrollElem.getBoundingClientRect().top + formtop;
      //console.log("ScrollY will be", ypos);

      document.scrollingElement.scrollTo(0, ypos - 30);
    }
  }

  doNext(evt)
  {
    if(evt)
      evt.preventDefault();

    if ([ 'results' ].includes(currentpage))
      return;

    //validate the current page
    var questionnode = document.getElementById(currentpage);
    var value = getQuestionAnswerValue(currentpage);
    var error = questiontypes.getQuestionClass(questionnode).checkError(value);

    dompack.toggleClass(questionnode, "effecttool__question--haserror", !!error);
    dompack.qS(questionnode, ".effecttool__errormessage").textContent = error || "";

    if(error)
    {
      reportNewEvent('Error', questionnode.dataset.questiontitle);

      // Return the focus back to the question (because it's not in a valid state yet)
      this.setFocusToFirstInput();

      return { success: false
             , page: currentpage
             };
    }

    this.answeredquestions.push(questionnode);
    reportOnQuestionAnswered(currentpage, (Date.now() - questiondisplaystart));
    window.history.pushState( { type: 'effectformpage' }, "");
    this.executePageTransition(true);

    this.setFocusToFirstInput();

    return { success: true
           , page: currentpage
           };
  }

  _doPrev(evt)
  {
    if(evt)
      evt.preventDefault();

    let gotoq = this.answeredquestions.pop();
    this._filterOnConditions();
    this._calcNext();
    this.goToQuestionById(this.getQuestionIdByNr(this.state.currentquestion.qnr), false);

    this.setFocusToFirstInput();
  }

  setFocusToFirstInput()
  {
    let focus = dompack.qS(".effecttool__page--visible input");
    if(focus)
      focus.focus();
  }

  _displayResults(results)
  {
    let pagecontainer = this.node.querySelector(".effecttool__results");
    console.log("displayResults", results);
    // $('effectgroups').empty();


    // maak de lijst van maatregelen waaraan de gebruiker voldoet,
    // voor op de eindpagina (results)


// FIXME: still necessary?
    var result = this._getResults({currentpage});//DEBUG
    this._filterOnConditions(); // DEBUG


// Set the hidden attribute
console.log("Filtering for points", this.currentpoints);
    dompack.qSA(".effecttool__effect").forEach(variant => variant.hidden = !results.variants.includes(variant.dataset.variantkey));


    // hide empty effectgroups
    dompack.qSA(".effecttool__group").forEach(effectgroup => effectgroup.hidden = !effectgroup.querySelector(".effecttool__effect:not([hidden])"));


  //FIXME log aantal tips naar onze eigen stats asl we dat al neit doen  window.dataLayer && window.dataLayer.push({aantalTips: results.variants.length});
    pagecontainer.classList.toggle("effecttool__results--nomeasures", results.variants.length == 0);
    dompack.qSA("#results .buttons").forEach(_ => _.classList.toggle("hide", results.variants.length == 0));

    reportNewEvent('Results', "", { value: results.variants.length });
    reportEvent("finished", { dn_measurecount: results.variants.length, db_isprogress: true });
    currentquestiontitle = "Resultaten";
    currentquestionuid = "results";

    dompack.qS('.effecttool__collectedanswers').innerHTML='';
    results.answers.forEach((item,idx) =>
    {
      var newrow = <div class="answer"/>;
      if (idx % 2 == 1)
        newrow.classList.add("odd");

      newrow.appendChild(<div class="title">{item.question}</div>);

      var answertext = item.answertext;
      if (answertext == "")
        answertext = "- geen antwoord -";

      newrow.appendChild(<span class="mobileseparator"><br/></span>); //FIXME who needs this, kill it? just flex it?

      if (!dompack.debugflags["tools-testmode"])
      {
        newrow.appendChild(<span class="answerlink">{answertext}</span>);
      }
      else
      {
        var answerlink = <span class="answerlink">{answertext}</span>
        answerlink.addEventListener('click', () => toolcontroller.goToQuestionById('question-' + item.nr, false));

        newrow.appendChild(answerlink);

        var editimage = <img class="editimage" src={whintegration.config.imgroot + "edit.png"} alt="Wijzig" />;
        editimage.addEventListener('click', () => toolcontroller.goToQuestionById('question-' + item.nr, false));

        newrow.appendChild(editimage);
      }

      dompack.qS('.effecttool__collectedanswers').appendChild(newrow);
    });

/*
type
variants
answers
questionsleft
points
*/
    dompack.dispatchCustomEvent(this.node, "toolplatform:resultspage",
          { bubbles: true, cancelable: false
          , detail:  results //{ answers: results.answers, points: results.points }});
          });
  }

  /** Update filtered_questions and filtered_measurevariants given that a specific question has been answered
  */
  _filterOnConditions()
  {
    this.state.history.forEach(elt => elt.used = false);

    this.state.filtered_questions = this.questions.slice();
    this.state.filtered_measurevariants = this.formsettings.effects.slice();

    //console.log("formsettings.effects", this.formsettings.effects);

    let answeredquestionids = this.answeredquestions.map(_ => _.dataset.uid);
    if(this.debug)
      console.log("Initially " + this.state.filtered_questions.length + " questions and " + this.state.filtered_measurevariants.length + " answers. answered: ", answeredquestionids);
    while (true)
    {
      // Get the list of questions referenced by the current valid effects
      let allquestionids = [];
      // this.state.filtered_measurevariants.forEach(elt => allquestionids.push(...elt.cqs));
      this.state.filtered_questions.forEach(q => allquestionids.push(q.uid));

      let initiallength = this.state.filtered_questions.length + this.state.filtered_measurevariants.length;

      if(this.debug)
        console.log("Before question filtering: " + this.state.filtered_questions.length, this.state.filtered_questions.map(_ => _.qnr));

      // Remove all questions that are not referenced by the effectgroups
      // DISABLED !!!
      //this.state.filtered_questions = this.state.filtered_questions.filter(elt => allquestionids.includes(elt.uid));

      if(this.debug)
        console.log("After filtering by active variants: " + this.state.filtered_questions.length, this.state.filtered_questions.map(_ => _.qnr));

      // or are explicitly hidden based on the current answers (and enabled questions)
      this.state.filtered_questions = this.state.filtered_questions.filter(elt => this._checkConditions(elt.conditions, allquestionids, answeredquestionids, elt));

      if(this.debug)
        console.log("After filtering by conditions: " + this.state.filtered_questions.length, this.state.filtered_questions.map(_ => _.qnr));

      // Remove all effects that are hidden based on the current answers (and enabled questions)
      if(this.debug)
        console.log("Before variant filtering: " + this.state.filtered_measurevariants.length, this.state.filtered_measurevariants.map(_ => _.uid));

      this.state.filtered_measurevariants = this.state.filtered_measurevariants.filter(elt => this._checkConditions(elt.conditions, allquestionids, answeredquestionids, elt));

//console.log("**************");
//console.log(this.state.filtered_measurevariants);
// dompack.qSA(".effecttool__effect").forEach(variant => variant.hidden = !results.variants.includes(variant.dataset.variantkey));

      if(this.debug)
        console.log("Atter variant filtering: " + this.state.filtered_measurevariants.length, this.state.filtered_measurevariants.map(_ => _.uid));

      if(this.state.filtered_questions.length + this.state.filtered_measurevariants.length == initiallength)
         break;  //no progress
    }
  }

  _processAnswer(questionuid, answeruids, date, amount)
  {
    let fullreeval = false;
    if (!this.state.currentquestion || questionuid !== this.state.currentquestion.uid)
    {
      // const rec = this.state.history.find(elt => elt.uid === questionuid);
      // if (!rec)
      //   return { code: "nosuchquestioninhistory", msg: "No such question in the history of this form" };

      const question = this.questions.find(elt => elt.uid === questionuid);
      if (!question)
        return { code: "nosuchquestion", msg: "No such question in this form" };

      if ((question.type === "radio" || question.type === "pulldown") && answeruids.length === 0)
        return { code: "invalidanswer", msg: "Invalid answer" };

      // Reinit currentquestion (might have been removed from filtered_questions already)
      this.state.currentquestion = question;

      // this.state.history = this.state.history.filter(elt => elt.qnr < rec.qnr);
      fullreeval = true;
    }
    else
    {
      if ((this.state.currentquestion.type === "radio" || this.state.currentquestion.type === "pulldown") && answeruids.length === 0)
        return { code: "invalidanswer", msg: "Invalid answer" };
    }

    let answertext;
    switch (this.state.currentquestion.type)
    {
      case "radio":
      case "pulldown":
      case "checkboxlist":
      {
        const answertexts = this.state.currentquestion.answers.filter(elt => answeruids.includes(elt.uid)).map(elt => elt.wrd_title);
        answertext = answertexts.join(", ");
      } break;
      case "amount":
      {
        answertext = amount + '';
      } break;
      case "date":
      {
        const jsdate = new Date(date);
        answertext = formatNLReadableDate(jsdate);
      } break;
    }

    const rec =
        { qnr:              this.state.currentquestion.qnr
        , question:         this.state.currentquestion.wrd_title
        , uid:              questionuid
        , answers:          this.state.currentquestion.answers.filter(elt => answeruids.includes(elt.uid)).map(elt => elt.uid)
        , date:             date
        , amount:           amount
        , answertext:       answertext
        , type:             this.state.currentquestion.type
        , used:             false
        };

    this.state.history.push(rec);
    this.state.lastanswer = rec;

    const fullelt = this.state.fullhistory.find(elt => elt.wrd_id === rec.wrd_id);
    if (fullelt)
      Object.assign(fullelt, rec);
    else
      this.state.fullhistory.push(rec);

    this._filterOnConditions();

    this._calcNext();
    return null;
  }


  /** Checks if a condition might evaluate to true.
      @param conditions List of AND-ed conditions
      @param allquestionids List of currently visible questions
      @param answeredquestionids List of currently answered questions
      @return Whether the condition evaluates to TRUE, or might with more answers
  */
  _checkConditions(conditions, allquestionids, answeredquestionids, elt)
  {
    let result = undefined;
    let truths = [];
    for (const cond of conditions)
    {
      result = undefined;
// /console.log("check condition", cond.question);
      if (cond.question == "__points")
      {
        // console.log(cond);
        // console.log("Current points", this.currentpoints);

        switch(cond.matchtype)
        {
          case "<":  { result = this.currentpoints <  cond.amount } break;
          case "<=": { result = this.currentpoints <= cond.amount } break;
          case "=":  { result = this.currentpoints == cond.amount } break;
          case ">=": { result = this.currentpoints >= cond.amount } break;
          case ">":  { result = this.currentpoints >  cond.amount } break;
          default:   { console.error("unsupported __points matchtype " + cond.matchtype); }
        }

        // haspoints = true;
        // console.log("Match?", result);

        if(result !== true)
        {
          setConditionResult(elt, `false: ${this.currentpoints} NOT ${cond.matchtype} ${cond.amount}`);
          return false;
        }

        truths.push(`${this.currentpoints} NOT ${cond.matchtype} ${cond.amount}`);
        continue;
      }


      if (!allquestionids.includes(cond.question))
      {
        setConditionResult(elt, `false: Depending on question '${cond.question}' which is unavailable`);
        return false;
      }
      if(!answeredquestionids.includes(cond.question))
      {
        truths.push(`Question '${cond.question}' hasn't been asked`);
        continue;
      }

      // No answer yet? That's ok
      // const answer = this.state.history.find(elt => elt.wrd_id === cond.question);
      // if (!answer)
        // continue;


      const answer = this.currentanswers.find(answer => answer.uid == cond.question);
      if(!answer)
        throw new Error("Missing answer?");

      answer.used = true;
      switch (cond.matchtype)
      {
        case "amount_is":             { throw 1; result = !(answer.amount !== cond.amount); } break;
        case "amount_isormorethan":   { throw 1; result = !(answer.amount < cond.amount); } break;
        case "amount_lessthan":       { throw 1; result = !(answer.amount >= cond.amount); } break;
        case "date_before":           { result = !(answer.value >= cond.date); } break;
        case "date_onorafter":        { result = !(answer.value < cond.date); } break;
        case "mc_allof":              { result = !(array_intersection(answer.value, cond.answers).length !== cond.answers.length); } break;
        case "mc_anyof":
        case "mc_singleanyof":        { result = !(array_intersection(answer.value, cond.answers).length === 0); } break;
        case "mc_is":                 { result = !(array_intersection(answer.value, cond.answers).length !== array_union(answer.value, cond.answers).length); } break;
        case "mc_noneof":
        case "mc_singlenoneof":       { result = !(array_intersection(answer.value, cond.answers).length !== 0); } break;
        case "isset_notset":          { result = !answer.value; break; }
        case "isset_isset":           { result = !!answer.value; break; }
      }

      if(result !== true)
      {
        setConditionResult(elt, `false: ${answer.value} NOT ${cond.matchtype} `,cond.answers);
        return false;
      }
    }

    setConditionResult(elt, `true: ${truths.join(',')}`);
    return true;
  }

  /** Determines the next visible question
  */
  _calcNext()
  {
    // const lastqnr = (this.state.lastanswer || { qnr: -1 }).qnr;

    //find first unanswered question
    let questionsleft = this.state.filtered_questions.filter(elt => !this.answeredquestions.includes(elt.node));
    this.state.currentquestion = questionsleft[0];
    this.state.questionsleft = questionsleft.length;
  }

  /** Give back what the current shown page should be
      @return
      @cell pagetype 'question' or 'results'
      @cell nr If pagetype = "question", the number of the question (1-based)
      @cell data Sorted list of variants
      @cell answers List of answers
  */
  _getPage()
  {
    if (this.state.currentquestion)
    {
      const question = this.state.currentquestion;

      return { pagetype:       "question"
             , nr:             question.qnr
             , questionsleft:  this.state.questionsleft
             };
    }
    else
    {
      let variants = this.state.filtered_measurevariants;

      return { pagetype:      "results"
             , data:          variants
             , answers:       funcValueSort(this.state.history.filter(elt => elt.used), elt => elt.qnr).map(elt => ({ nr: elt.qnr , ...elt }))
             , questionsleft:  this.state.questionsleft
             };
    }
  }
  _processAnswers()
  {
    for (const answer of this.currentanswers)
    {
      let answeruids = [];
      let date = "";
      let amount = 0;

      switch (answer.type)
      {
        case "pulldown":      answeruids = [ answer.value ];  break;
        case "radio":
        case "checkboxlist":  answeruids = answer.value; break;
        case "date":          date = answer.value; break;
        case "amount":        amount = Number(answer.value) | 0; break;
      }

      this._processAnswer(answer.uid, answeruids, date, amount);
    }
  }

  _submitFormAnswers()
  {
    this._processAnswers();

    const formdata = this._getPage();
    let formresult = { numquestions:  this.questions.length
                     };

    if (formdata.pagetype === "question")
    {
      return { ...formresult
             , type:          "question"
            // FIXME: also add variants??
             , questionnr:    formdata.nr
             , questionsleft: formdata.questionsleft
             , answers:       this.currentanswers
             , points:        this.currentpoints
             };
    }

    return { ...formresult
           , type:          "results"
           , variants:      this.state.filtered_measurevariants.map(el => el.uid)
           , answers:       this.currentanswers
           , questionsleft: formdata.questionsleft
           , points:        this.currentpoints
           };
  }

  _gatherAnswers(options)
  {
    options = { considercurrentpage: true, ...options};

    var answers = [];
    var questions = dompack.qSA(".question");

    // Count all earned points
    let points = 0;
    let pontcalculation = [];

    for (var i = 0; i < questions.length; ++i)
    {
      var question = questions[i];
      let questionapi = questiontypes.getQuestionClass(question);
      var id = question.getAttribute('id');
      if (!options.considercurrentpage && id == options.currentpage)// && !skiptoresults)
        break;

      if (this.answeredquestions.includes(question))
      {
        var value = getQuestionAnswerValue(id);

        answers.push({ uid:          question.getAttribute('data-uid')
                     , type:         question.getAttribute('data-type')
                     , points:       questionapi.getAnswerPoints ? questionapi.getAnswerPoints() : 0
                     , value:        value
                     , question:     questionapi.getTitle()
                     , answertext:   questionapi.getAnswerText()
                     , redirecturl:  questionapi.getRedirectURL ? questionapi.getRedirectURL() : ""
                     });
      }

      if (id == options.currentpage)// && !skiptoresults)
        break;
    }

    for(let answer of answers) //this.currentanswers)
      points += answer.points ? answer.points : 0;

    if(console.table)
    {
      console.table(answers.map(_ => ({ q: _.question, a: _.answertext, points: _.points})));
      console.log("Total points: ",points);
    }
    this.currentpoints = points;

    return answers;
  }

  _getResults(options)
  {
    this.currentanswers = this._gatherAnswers(options);
    return this._submitFormAnswers();
  }
}


export function describeQuestionAndAnswer(questioncontainerid)
{
  let question = document.getElementById(questioncontainerid);
  let type = question.dataset.type;
  let inputs = dompack.qSA(question, "input");
  let checkedinputs = dompack.qSA(question, "input:checked");
  let selectoptions = dompack.qSA(question, "option:checked");
  let qclass = questiontypes.getQuestionClass(question);

  let description = { questionuid: question.dataset.uid
                    , question: question.dataset.questiontitle
                    , answeruid: ""
                    , answer: ""
                    , reportanswer: ""
                    };

  switch (type)
  {
    case 'date':
      {
        // Make sure the value is in format YYYY-MM-DD, we'll sort on it later
        var dateday   = ('0000' + question.querySelector(".date-day").value).slice(-2);
        var datemonth = ('0000' + question.querySelector(".date-month").value).slice(-2);
        var dateyear  = ('0000' + question.querySelector(".date-year").value).slice(-4);
        description.answer = dateyear + "-" + datemonth + "-" + dateday;
        description.eventanswer = dateyear;
      } break;
    case 'amount':
    case 'coronageldhulp:postcode':
      {
        description.answer = inputs[0].value;
        description.eventanswer = inputs[0].value;
      } break;
    case 'pulldown':
      {
        description.answeruid = selectoptions.length ? selectoptions[0].value : "";
        //FIXME get a data attribute, textContent is broken by translate
        description.answer = selectoptions.length ? selectoptions[0].textContent : "";
        description.eventanswer = description.answer;
      } break;
    case 'checkboxlist':
    case 'radio':
      {
        description.answeruid = checkedinputs.map(item => item.value).join(', ');
        //FIXME get a data attribute, textContent is broken by translate
        description.answer = checkedinputs.map(item => item.parentNode.textContent).join(', ');
        description.eventanswer = checkedinputs.map(item => item.parentNode.textContent).join('; ');
      } break;
    default:
      description.answer = qclass.getAnswerForStats();
  }

  return description;
}

export function reportOnQuestionShown(question)
{
  const described = describeQuestionAndAnswer(question);
  currentquestiontitle = described.question;
  currentquestionuid = described.questionuid;

  reportNewEvent("AskQuestion", described.question);
  reportEvent("question",
      { ds_question:    described.question
      , ds_questionuid: described.questionuid
      , db_isprogress: true
      });
}

export function reportOnQuestionAnswered(question, time)
{
  const described = describeQuestionAndAnswer(question);
  reportNewEvent("GotAnswer", described.question + ":" + described.answer);
  reportEvent("answer",
      { ds_question:    described.question
      , ds_questionuid: described.questionuid
      , ds_answer:      described.answer
      , ds_answeruid:   described.answeruid
      , dn_time:        time
      });
}

function checkClick(evt)
{
  let link = evt.target.closest('a[href]');

  if(link && (link.href.startsWith("http:") || link.href.startsWith("https:")))
  {
    reportEvent("externallinkclick",
        { ds_text:   link.textContent
        , ds_url:    link.href
        });
  }
}


export function getDateOfBirth()
{
  let dob = thecontroller.getDOBElement();
  if(!dob)
    return null;

  return questiontypes.getQuestionClass(dob).getValue();
}
