import * as dompack from "dompack";
import "./skiplinks.css";


// distance we want to keep to the sticky navigationbar when scrolling to an element
let tonavbardistance = 0; // we already have some whitespace between the menubar and pageheader


dompack.onDomReady(init);


function init()
{
  document.querySelector(".skiplinksbutton").addEventListener("click", doSkipLinks);
}

function doSkipLinks(evt)
{
  evt.preventDefault();

  let pageheader = document.querySelector(".pageheader");
  let main = document.querySelector("main");

  let focusnode = pageheader ?? main;
  let scrolltonode = pageheader ?? main;
  let obscuredareaheight = 0;


  if (!focusnode.hasAttribute("tabindex"))
    focusnode.setAttribute("tabindex", "-1"); // allow focus, but not in tab order

  console.log("Set focus to", focusnode);
  console.log("Scroll to", scrolltonode);

  focusnode.focus();


  // NOTE: That height of the menu obscuring part of the content will change when we scroll.
  //       This means we cannot simply measure the height of the menu here..
  // (or use the height + the negatice top)


  let menubar = document.querySelector(".header-menubar");
  let cstyle = window.getComputedStyle(menubar);
  if (cstyle.position == "fixed" || cstyle.position == "sticky")
  {
    // Compensate for part of the viewport being covered
    obscuredareaheight = menubar.clientHeight;
  }

  //console.info("obscuredareaheight", obscuredareaheight);

  scrolltonode.style.scrollMarginTop = (obscuredareaheight + tonavbardistance) + "px";
  scrolltonode.scrollIntoView({ behavior: "smooth" });
}
