import "./mobileinpagelinks.scss";

window.addEventListener("click", evt => doCheckForClose(evt));

function doCheckForClose(evt)
{
  const closebutton = evt.target.closest(".inpagelinksdropdown__closebutton");
  if (!closebutton)
    return;

  const detailselement = evt.target.closest("details");
  if (!detailselement)
    return;

  detailselement.removeAttribute("open");
}
