import * as dompack from "dompack";
import FilteredOverview from "@mod-acoi/webdesigns/shared/components/filteredoverview";

import "./search.scss";



dompack.register(".searchpage-subfilterform", initSearchPageFilters);


function initSearchPageFilters(formnode)
{
  console.log("initSearchPageFilters", formnode);

  let resultsnode = document.querySelector(".searchresults");
  let paginationnode = document.querySelector(".searchresults-pagination");

  let settings =
          { trackingid:             "site-search" // name for analytics (and debug)

          , method:                 "clientside"
          , resultsperpage:         10

          , node_results:           resultsnode
          , node_pagination:        paginationnode // container for pagination
          , resultsanchor:          resultsnode
          , items:                  resultsnode.querySelectorAll(".searchresult")

          , onfilterchange:         onFilterChange
          };

  window.filteredoverview_publications = new FilteredOverview(formnode, settings);
  window.filteredoverview_publications.refreshResults();
}

function onFilterChange(filters)
{
  filters.query = document.querySelector(".pageheader__searchpanel form").query.value;
  return filters;
}
