import * as dompack from "dompack";
import * as effecttool from "@mod-toolplatform/tools/effecttool";
import * as whintegration from "@mod-system/js/wh/integration";
//import * as toolhelpers from "@mod-kenjebankzaken/webdesigns/shared/effectgroups-as-accordeon.es";

import "./tool-variables.scss";

import "./tool.scss";
import "./tool-progressbar.css";
import "./tool-options.css";


import "./tool-page.scss";

import tippy from 'tippy.js';

/*
// import tippy, {roundArrow} from 'tippy.js';
//import tippy, {followCursor} from 'tippy.js/headless';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/dist/svg-arrow.css';
*/


dompack.register(".effecttool__root", initToolExtras);

let toolnode;

function initToolExtras(node)
{
  toolnode = node;
  /*
  FIXME: Google Analytics 4 will die soon..

  if (whintegration.config.dtapstage == "production")
    effecttool.setupGA("UA-xxxxxxxxx-x", "Tool");
  */

  // Listen for the tool to be finished. When it shows the resultpage we go to page 1b (our custom resultpage) and enable the button to page 2
  node.addEventListener("toolplatform:progress", onToolPageSwitch);
  node.addEventListener("toolplatform:resultspage", onToolResultsPage);  




  let copylinkbutton = document.querySelector(".customtool__copyprefilllink");
  if (copylinkbutton)
    copylinkbutton.addEventListener("click", doCopyPrefillLinkToClipboard);

  let restartbutton = document.querySelector(".customtool__restart");
  if (restartbutton)
    restartbutton.addEventListener("click", doRestart);

  moveInfoButtonsToQuestionHeader(); // handle the question info buttons
  // tippy('[data-tippy-content]'); // handle custom info buttons in the witty


  //prefillAnswersFromURL();

  // This is the same way the Toolplatform iterates through the URL arguments (in prefillOtherQuestions),
  // however it only prefills and we skip to the next question(s).
  const url = new URL(location.href);
  for(const entry of url.searchParams.entries())
  {
    if(!entry[0].startsWith('answer-'))
      continue;

    let result = window.__toolcontroller.doNext();
  }
}



// Move the info in the DOM and attach Tippy so the info appears as tooltip
function moveInfoButtonsToQuestionHeader()
{
console.info("moveInfoButtonsToQuestionHeader");

  let questionnodes = toolnode.querySelectorAll(".effecttool__question");

  for (let questionnode of questionnodes)
  {
    let moreinfo = questionnode.querySelector(".effecttool__moreinfo");

console.log("moreinfo", moreinfo);
    if (!moreinfo)
      continue;

    let new_info = getInfoButton(); //moreinfo.innerHTML);

    // new_info.querySelector(".avtool__infobutton__popup").appendChild(moreinfo);
    tippy( new_info //new_info.querySelector("button")
         , { content: moreinfo.innerHTML
           , allowHTML: true
         //, hideOnClick: false // set to false for testing
           , trigger: 'mouseenter click'
           });
    moreinfo.parentNode.removeChild(moreinfo); // remove the original moreinfo node


    let questiontext_node = questionnode.querySelector(".effectool__questiontext");


    let questiontext_h2 = questiontext_node.querySelector("h2");

    if (questiontext_h2)
      questiontext_h2.appendChild(new_info);
    else
      questiontext_node.appendChild(new_info);

//    questiontext_node.insertBefore(new_info, questiontext_node.firstChild);
  }
}

function getInfoButton()
{
  return <button class="infobutton"
                 type="button"
                 aria-label="Uitleg"
                 ></button>;
                 // aria-describedby="[instanceid]__tooltip"
/*
  return <div class="avtool__infobutton">
           <button class="avtool__infobutton__button" tabindex="0"></button>
         </div>;
*/
}


function onToolPageSwitch(evt)
{
  /*
  NOTES:
  - at this point .effecttool__page--visible is still the page from before .nextPage() was called
  - window.__toolcontroller contains the ToolController instance (we assume to only have one tool on the page)
  */

  console.log("toolplatform:progress", evt.detail);
/*
  setProgress( evt.detail.answers.length + 1
               , evt.detail.numquestions - evt.detail.questionsleft + 1
               , evt.detail.numquestions);
*/
  let nextpage = evt.detail.nextpage;
  if (!nextpage.question) // not a question page (probably the resultpage)
    return;
}



function onToolResultsPage(evt)
{
  console.group("onToolResultsPage");
  console.log("[avtool] Results page", evt.detail);


document.documentElement.classList.add("onresultspage");

//  let resultsheader = document.querySelector(".effecttool__results__header");


//  if (evt.detail.points)

/*
  let bdate_answer = results.answers.find(function(answer) { return answer.question.toLowerCase() == "geboortedatum"; });
  let prefilllink = generateURLFromAnswers(evt.detail.answers);
*/

console.log(evt.detail);

  //console.log("Answers", evt.detail.answers);

  let results = evt.detail;
  window.toolresult = results;

  // let results = getToolResults(); // gives back some more info than passed in evt.details (points and measuregroups) - NOT EXPOSED
  console.log("getToolResults", results);
  console.groupEnd();

 // morphEffectsIntoAccordeon();

  // openFirstVisibleEffectGroup();

  // makePartOfEffectsGroupTitleBold();

  // updateEffectsCountText(evt);

  // addOddEvenClassnamesToEffects();

/*
  prefilllink = generateURLFromAnswers(evt.detail.answers);


  //if (whintegration.config.obj.mailresultstext)
  //  finalurl += "&" + encodeURI(whintegration.config.obj.mailresultstext);

  // Update mailto: URL in button
  let subject = whintegration.config.obj.mailresultssubject;
  let body = whintegration.config.obj.mailresultstext + "\n\n" + prefilllink;
  let anchorbutton = document.querySelector(".customtool__mailprefilllink");
  anchorbutton.href = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
*/
}
