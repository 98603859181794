import "./menubar.scss";
import "./menubar-pulldowns.scss";
import * as dompack from "dompack";
import * as domfocus from 'dompack/browserfix/focus';


dompack.register(".spc-menubar", initMenuBar);

let debug = false;

let openedmenutree = null;
let openedmenutree_activatornode = null; // anchor or togglebutton which was focused before the panel was opened
let openedmenutree_panel = null; // dropdown panel
let openedmenutree_panel_iscustom = false;

/* After the "escape" key is pressed we need to prevent any focus loss until the bubbling phase
  of keyup has finished, otherwise ReadSpeaker's webReader will steal our focus. */
let lockfocus          = false; // don't allow focus to go outside the menubar: upon focusout steal back focus (needed because webReader steals focus in keyup event when "Escape" is pressed)
let lockfocus_duration = 5000;  // max duration of locking the focus in milliseconds (normally the lock should be released after the bubbling phase of the keyup event)
let lockfocus_timeout  = null;

window.__open = openedmenutree;


function initMenuBar(node)
{
  console.info("[spc-menubar] Inializing", node);

  /*
  Accessible menu:

  - Generic
    - After initialization .spc-menubar will get role="menubar"
      This is done both to signal the availability of special keyboard navigation
      and to allow using CSS for non-JS :hover fallback (.spc-menu:not([role="menubar"]) ...:hover)
      This is also usefull in case an error in the assetpack causing the menu code not to be initialized.
    - Has a workaround for preventing ReadSpeakers's webReader to steal our focus when "Escape" is pressed

  - DOM
    - aria-controls is used on the anchor (because hover controls opening of the popup)
    - aria-controls is used on the button (because enter/space controls opening of the popup)
    - after initialization a class is set to ensure opening of menu's is fully handled by Javascript
      (so an menu opened by hover can be closed by keyboard as required by WCAG)
    - use of "open" attribute on the panel that is opened
      (just as with an accordion or tabpanel. upside is we can use hidden="until-found")
      and check whether a menu item is in a hidden panel by looking for .closest("hidden")

  - The first level of menu items can be opened by:
    - hovering over either the title/link or pulldown toggle button
    - pressing arrow down while title or pulldown toggle is focused
    - using the pulldown toggle button

  - The first level of menu items can be closed by:
    - pressing esc on the keyboard
    - moving the mouse to another toplevel menuitem
    - moving the mouse outside the menubar
    - using the pulldown toggle button

  - Navigation
    - "Arrow Left" and "Arrow right" can be used to navigate through the menubar (1st level of menu)
    - "Arrow up" and "Arrow down" can be used to navigate through the pulldown


  FIXME: maybe ignore the focus events which happen when we ourselves set focus..?
  */

  // We must use keydown so we can still prevent the page scrolling
  // when using the arrow keys to nativate the menu
  //document.body.addEventListener("keydown", evt => checkMenuKeyboardInteraction(evt)); // for handling "esq" to close open pulldown
  window.addEventListener("keydown", evt => checkMenuKeyboardInteraction(evt), { capture: true }); // for handling "esq" to close open pulldown
  window.addEventListener("keyup", evt => keyup(evt));

  node.addEventListener("focusout",   evt => checkMenuFocusEvent(evt)); // when focus moves from inside to outside tabpanel the pulldown must close (except when lockfocus is active - in which we case we steal the focus back)

  node.addEventListener("mouseover",  evt => checkMenuMouseEvent(evt)); // moving over a new part of the menubar may trigger a new pulldown
  node.addEventListener("mouseout",   evt => checkMenuMouseEvent(evt)); // moving out of a part of the menubar may close a pulldown (unless relatedTarget is still within the current branch/pulldown)
  node.addEventListener("mouseleave", evt => checkMenuMouseEvent(evt)); // leaving the menubar with the pointer closes any open pulldowns

  node.addEventListener("click", doToggleMenuItems);


  // Enrich menubar with aria-expanded and aria-controls attributes
  let expandable_menubar_items = node.querySelectorAll(".spc-menubar__item--hassubitems"); // branches
  let pulldownidx = 0;
  for (let branch of expandable_menubar_items)
  {
    let anchor         = branch.querySelector(".spc-menubar__item a");
    let togglebutton   = branch.querySelector(".spc-menubar__item__toggle");


    // If there's already an aria-controls, it means we use a custom panel
    // .spc-menubar-custompaneltrigger
    if (!togglebutton)
      continue;
      // console.error("no togglebutton in", branch);

    let panelid = togglebutton.getAttribute("aria-controls");
    let subitems_panel = null;

    if (panelid)
    {
      console.info("PANEL ID CUSTOM", panelid);

      subitems_panel = document.getElementById(panelid);
      subitems_panel.addEventListener("mouseleave", evt => checkMenuMouseEvent(evt)); // leaving the menubar with the pointer closes any open pulldowns
      continue;
    }

    subitems_panel = branch.querySelector(".spc-menubar__pulldown");
    if (!subitems_panel) // it's a 3th/4th level
      subitems_panel = branch.querySelector("ul"); // can be a pulldown (for 2nd level) or just nested

    if (debug)
    {
      console.log({ branch:         branch
                  , achor:          anchor
                  , togglebutton:   togglebutton
                  , subitems_panel: subitems_panel
                  });
    }

    if (!subitems_panel)
    {
      console.warn("Item is wrapped in .spc-menubar__item--hassubitems but has no .spc-menubar__pulldown");
      continue;
    }

    const panel_id = "menubar-pulldown-" + pulldownidx;
    subitems_panel.id = panel_id;
    subitems_panel.setAttribute("hidden", "");

    if (anchor)
    {
      anchor.setAttribute("aria-controls", panel_id);
      anchor.setAttribute("aria-expanded", "false");
    }

    if (togglebutton)
    {
      togglebutton.setAttribute("aria-controls", panel_id);
      togglebutton.setAttribute("aria-expanded", "false");
    }
    else
      console.warn("Item is wrapped in .spc-menubar__item--hassubitems but havn't got a .spc-menubar__item__toggle");

    pulldownidx++;
  }

  node.setAttribute("role", "menubar");
}


function keyup(evt)
{
  if (debug)
    console.log(evt.type, evt.key);

  // Release focus after the bubble phase of the keyup event
  // (the setTimeout makes sure the code is executed after this event is handled).
  // This makes sure webReader is ready stealing focus (since it uses keyup to detect "Escape" key usage and steal focus)
  // and we can safely steal it back.
  if (lockfocus)
  {
    if (debug)
      console.info("Release focus lock due to keyup");

    setFocusUnlockTimer(0);
  }
}


function checkMenuFocusEvent(evt)
{
  // console.log(evt);
  // console.group(evt.type, evt.target);
  __checkMenuFocusEvent(evt);
  // console.groupEnd();
}



function isNodeInCurrentBranch(node)
{
  if (!openedmenutree)
    return false;

  // Check if we moved outside any part of the DOM related to the open menu tree
  let node_belongs_to_current_branch =
          openedmenutree.contains(node) // mouse not mouse within the nested elements for this menu branch?
      || (openedmenutree_panel && openedmenutree_panel.contains(node)); // mouse not within the opened panel (which can be nester OR a totally seperate element on the page)

  /*
  if (debug)
  {
    console.log("isNodeInCurrentBranch",
          { openedmenutree:               openedmenutree
          , openedmenutree_activatornode: openedmenutree_activatornode
          , openedmenutree_panel:         openedmenutree_panel
          , node_belongs_to_current_branch: node_belongs_to_current_branch
          });
  }
  */

  return node_belongs_to_current_branch;
}

// FIXME: might be used later
function inMenuToplevel(node)
{
  let menucontainer = node.closest(".spc-menubar");
  if (!menucontainer)
    return false; // might be in a custom panel, so it's for sure not a toplevel item

  // We should only encounter a single <ul>...
  // toggle must use this... so we save the openmenutree
  let levelcount = 0;
  let walknode = node;
  while(walknode && !walknode.classList.contains("spc-menubar"))
  {
    // console.info(walknode.tagName);
    if (walknode.tagName == "UL")
      levelcount++;

    walknode = walknode.parentNode;
  }

  // console.log("LEVEL", levelcount);

  // 0 = we probably are inside a custom panel
  return levelcount == 1;
}




function preventFocusStealing()
{
  if (debug)
    console.info("Setting focus lock");

  lockfocus = true;
  setFocusUnlockTimer(lockfocus_duration); // this is a fallback in case the "keyup" doesn't seem to happen
}

function setFocusUnlockTimer(duration)
{
  if (lockfocus_timeout !== null)
    clearTimeout(lockfocus_timeout);

  lockfocus_timeout = setTimeout(releaseFocusLock, duration);
}

function releaseFocusLock()
{
  if (debug)
    console.info("Releasing focus lock");

  lockfocus = false;
  lockfocus_timeout = null;
}



function __checkMenuFocusEvent(evt)
{
  if (evt.type != "focusout")
    return;

  // ReaderSpeaker's webReader is known to steal focus when "Escape" is pressed,
  // this kills our accessibility because closing a menu dropdown can webReader
  // to move the focus to whatever place in the DOM/website it's button is placed.
  // We must prevent this, otherwise the menu will not adhere to the accessibility guidelines
  // and will be mentioned in accessibility audits.
  if (lockfocus && !isNodeInCurrentBranch(evt.relatedTarget))
  {
    console.warn("Focus was stolen. Now stealing it back!");
    evt.target.focus(); // set focus back to the element which had a focusout event (this should be within our menu)
    return;
  }

  if (!openedmenutree)
    return;

  if (!isNodeInCurrentBranch(evt.relatedTarget))
    closeMenuBranch(openedmenutree);
}



function checkMenuKeyboardInteraction(evt)
{
  if (debug)
  {
    if (evt instanceof KeyboardEvent)
      console.group(`%c${evt.type} "${evt.key}"`, "font-size: 120%;");
    else
      console.group("%c" + evt.type, "font-size: 120%;");
  }

  /*
  console.log("Event fired on", evt.target);
  console.log(
        { openedmenutree:               openedmenutree
        , openedmenutree_activatornode: openedmenutree_activatornode
        , openedmenutree_panel:         openedmenutree_panel
        });
  */

  try
  {
    if (evt.key === "Escape" && openedmenutree)
    {
      evt.preventDefault();
      evt.stopPropagation();

      closeMenuBranch(openedmenutree);
      return false;
    }

    __checkMenuKeyboardInteraction(evt);
  }
  finally
  {
    if (debug)
      console.groupEnd();
  }
}



function __checkMenuKeyboardInteraction(evt)
{
  // only handle Escape if we have a menu open, otherwise some other component may want to handle escape
  if (evt.key === "Escape" && openedmenutree)
  {
    evt.preventDefault();
    evt.stopPropagation();
    evt.returnValue = false;

    closeMenuBranch(openedmenutree);
    return false;
  }

  // When using tab on the last focusable item in a custom dropdown,
  // we want to move back to the menubar.
  if (evt.key == "Tab" && !evt.shiftKey && openedmenutree_panel && openedmenutree_panel_iscustom)
  {
    let focusable_nodes = domfocus.getFocusableComponents(openedmenutree_panel);
    if (document.activeElement == focusable_nodes[focusable_nodes.length - 1])
    {
      // Move focus to the item after the item which triggered the custom dropdown.
      // This mimics the custom pulldown being part of the menubar DOM.
      let group = openedmenutree_activatornode.closest('[role="group"]');
      let focusitem = getMenuItemAfter(group, /*FIXME*/ true, openedmenutree_activatornode);
      focusitem.focus();
      evt.preventDefault();

      closeMenuBranch(openedmenutree);
      return false;
    }
  }

  // Don't handle keys other than escape unless the menubar has focus
  if (!evt.target.closest(".spc-menubar"))
    return;


  // FIXME: support for handling custom dropdowns/panels
  let pulldown_panel = evt.target.closest(".spc-menubar__pulldown");
  //console.log(evt.target, pulldown_panel);
  if (pulldown_panel)
    handleVerticalMenuKeyboardEvents(evt);
  else
    handleHorizontalMenuKeyboardEvents(evt);
}


function handleHorizontalMenuKeyboardEvents(evt)
{
  if (debug)
    console.info("handleHorizontalMenuKeyboardEvents", evt.key);

  // the item (branch) in the menu the focus is on
  let branchnode = evt.target.closest(".spc-menubar-branch--horizontal > .spc-menubar__item--hassubitems");

  // menubar
  let menubarnode = evt.target.closest(".spc-menubar-branch--horizontal");


  if (evt.key === "ArrowRight")
  {
    setFocusToNextMenuItem(menubarnode, true);
    evt.preventDefault();
    evt.stopPropagation();
    return;
  }

  if (evt.key === "ArrowLeft")
  {
    setFocusToPreviousMenuItem(menubarnode, true);
    evt.preventDefault();
    evt.stopPropagation();
    return;
  }

  if (evt.key === "ArrowDown")
  {
    // ArrowDown on horizontal menu triggers a pulldown
    let item = evt.target.closest(".spc-menubar__item");
/*
    if (evt.target.classList.contains("spc-menubar-custompaneltrigger"))
    {
      // custom panels don't need to be nested,
      // so we don't need to check
    }
    else*/ if (item.parentNode != branchnode)
      return;

    //console.info("Item", item, "opens branch", branchnode);

    // Whe are in a menu item in a horizontal strip
    openMenuBranch(branchnode, true);
    openedmenutree_activatornode = evt.target;

//console.log("toggle", item.querySelector(".spc-menubar__item__toggle"));

    // Find first item in the pulldown
    // let pulldown_panel = branchnode.querySelector(".spc-menubar__pulldown");

    // Use the aria-controls to find the panel
    // (so it'll work for custom panels, pulldown panels, submenu popup panels)
    let pulldown_panel_id = item.querySelector(".spc-menubar__item__toggle").getAttribute("aria-controls");
    let pulldown_panel = document.getElementById(pulldown_panel_id);

    // Set focus on the first focusable item in the panel
    let focusable_nodes = domfocus.getFocusableComponents(pulldown_panel);
    if (focusable_nodes.length > 0)
      focusable_nodes[0].focus();

    /*
    console.log({ pulldown_panel_id: pulldown_panel_id
                , pulldown_panel:    pulldown_panel
                , focusable_nodes:   focusable_nodes
                });
    */

    evt.preventDefault();
    evt.stopPropagation();
    return;
  }

  if (evt.key === "Home")
  {
    let firstfocusable = menubarnode.querySelector(".spc-menubar-branch--horizontal > li > .spc-menubar__item > a, .spc-menubar-branch--horizontal > li > .spc-menubar__item > button");
    if (firstfocusable)
      firstfocusable.focus();
  }

  if (evt.key === "End")
  {
    let focusableitems = menubarnode.querySelectorAll(".spc-menubar-branch--horizontal > li > .spc-menubar__item > a, .spc-menubar-branch--horizontal > li > .spc-menubar__item > button");
    if (focusableitems.length > 0)
      focusableitems[focusableitems.length-1].focus();
  }
}



function handleVerticalMenuKeyboardEvents(evt)
{
  if (debug)
    console.info("handleVerticalMenuKeyboardEvents", evt.key);

  let menupanel = document.activeElement.closest(".spc-menubar__pulldown");

  if (evt.key === "ArrowDown")
  {
    setFocusToNextMenuItem(menupanel, false);
    evt.preventDefault();
    evt.stopPropagation();
    return;
  }

  if (evt.key === "ArrowUp")
  {
    setFocusToPreviousMenuItem(menupanel, false);
    evt.preventDefault();
    evt.stopPropagation();
    return;
  }

  // ArrowRight must toggle submenu's
  if (evt.key === "ArrowRight")
  {
    let item = evt.target.closest(".spc-menubar__item");
    let branchnode = item.parentNode;
    if (!branchnode.classList.contains("spc-menubar__item--hassubitems"))
      return; // This item doesn't have a submenu

    openMenuBranch(branchnode);
  }


  // ArrowLeft can close a submenu
  if (evt.key === "ArrowLeft")
  {
    let item = evt.target.closest(".spc-menubar__item");
    let branchnode = item.parentNode;
    if (!branchnode.classList.contains("spc-menubar__item--hassubitems"))
      return; // This item doesn't have a submenu

    closeMenuBranch(branchnode);
  }

}

function setFocusToPreviousMenuItem(menupanel, horizontal)
{
  let visible_anchors = getBrowseableItemsInMenuGroup(menupanel, horizontal);

  let currentidx = Array.from(visible_anchors).indexOf(document.activeElement);
  if (currentidx == -1) // ?
    return;

  if (currentidx > 0) // our current activeElement is with the list and it's not the first item in the list?
  {
    visible_anchors[currentidx-1].focus();
  }
  else if (!horizontal) // FIXME: we assume being in the pulldown - so up will close this menu
  {
    // Close the branch
    // (which should return us to the links or togglebutton which opened the pulldown)
    let branchnode = menupanel.closest(".spc-menubar__item--hassubitems");
    closeMenuBranch(branchnode);
  }
}



function getMenuItemAfter(menupanel, horizontal, afterelem)
{
  let visible_anchors = getBrowseableItemsInMenuGroup(menupanel, horizontal);

  let currentidx = Array.from(visible_anchors).indexOf(afterelem);
  if (currentidx == -1) // ?
    return;

  if (currentidx < visible_anchors.length-1)
    return visible_anchors[currentidx+1];

  return null;
}


function setFocusToNextMenuItem(menupanel, horizontal)
{
  let elem = getMenuItemAfter(menupanel, horizontal, document.activeElement);
  if (elem)
    elem.focus();
}


function getBrowseableItemsInMenuGroup(pulldown_panel, horizontal)
{
  let all_anchors;
  if (horizontal) // when navigating horizontally we also navigate through the togglers
    all_anchors = pulldown_panel.querySelectorAll("a,button.spc-menubar__item__toggle");
  else
    all_anchors = pulldown_panel.querySelectorAll("a");

  let visible_anchors = [];
  for(let anchor of all_anchors)
  {
    let closed_path = anchor.closest('[hidden]');
    if (!closed_path)
      visible_anchors.push(anchor);
  }
  /*
  console.log("All anchors in pulldown:", all_anchors);
  console.log("Visible anchors:", visible_anchors);
  */

  return visible_anchors;
}



function checkMenuMouseEvent(evt)
{
  if (evt.type == "mouseleave") // left the menubar(or descendant DOM) or a custom panel
  {
    // If the mouse is leaving to an element which isn't part of the current menu branch,
    // we must close the current menu branch.
    if (openedmenutree && !isNodeInCurrentBranch(evt.relatedTarget))
      closeMenuBranch(openedmenutree);

    return;
  }


  // When hovering over a new node in our menubar we need to check if
  // the pointer has moved to another menu branch.
  if (evt.type == "mouseover")
  {
    let menuitem = evt.target.closest(".spc-menubar__item");
    if (!menuitem)
      return;

    let branchnode = menuitem.closest(".spc-menubar > ul > .spc-menubar__item--hassubitems");
    if (!branchnode)
      return;

    openMenuBranch(branchnode, true);
    openedmenutree_activatornode = menuitem.querySelector(".spc-menubar__item__title,.spc-menubar__item__toggle");
  }


  // When leaving a node inside the menubar we need to check if we left the current branch
  // (if so we need to close our menu)
  if (evt.type == "mouseout")
  {
    if (!isNodeInCurrentBranch(evt.relatedTarget))
    {
      closeMenuBranch(openedmenutree);
      return;
    }
  }
}



//░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
//
//  DOM and state manipulation
//
//░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░

function openMenuBranch(branchnode, toplevel)
{
  if (debug)
    console.info("openMenuBranch", branchnode);

  if (openedmenutree && openedmenutree != branchnode && !openedmenutree.contains(branchnode))
    closeMenuBranch(openedmenutree);

  updateOpenStateInDOM(branchnode, true);

  if (toplevel)
    openedmenutree = branchnode;
}

function toggleMenuBranch(branchnode)
{
  let isopen = branchnode.classList.contains("spc-menubar__item--expand");

  if (isopen)
    closeMenuBranch(branchnode);
  else
    openMenuBranch(branchnode);//, activatornode);

  return !isopen;
}

function closeMenuBranch(branchnode)
{
  if (debug)
    console.info("closeMenuBranch", branchnode);

  updateOpenStateInDOM(branchnode, false);
}

function updateOpenStateInDOM(branchnode, openstate)
{
  if (debug)
    console.log("updateOpenStateInDOM", branchnode, "to", openstate);

  if (branchnode == null)
  {
    console.error("updateOpenStateInDOM must get a branchnode. High level functions must check whether the is an open branch.");
    return;
  }

  // Update aria-expanded on the link (because on hover it also toggles)
  // (the item has both an anchor(link) and togglebutton in case of a folder with an index (landing) page)
  let anchor = branchnode.querySelector(".spc-menubar__item a");
  if (anchor)
    anchor.setAttribute("aria-expanded", openstate ? "true" : "false");

  // Update aria-expanded on the toggle button
  let branchtogglebutton = branchnode.querySelector(".spc-menubar__item__toggle");
  let triggers_custom_panel;
  if (branchtogglebutton)
  {
    branchtogglebutton.setAttribute("aria-expanded", openstate ? "true" : "false");
    triggers_custom_panel = branchtogglebutton.classList.contains("spc-menubar-custompaneltrigger");
  }

  let subitems_panel_id = branchtogglebutton.getAttribute("aria-controls");
  let subitems_panel = document.getElementById(subitems_panel_id);
  openedmenutree_panel = subitems_panel;
  openedmenutree_panel_iscustom = triggers_custom_panel;



  //let subitems_panel = document.getElementById(branchtogglebutton.getAttribute("aria-controls"));
  // console.info("subitems_panel_id", subitems_panel_id);
  // console.info("subitems_panel", subitems_panel);

  // If the focus was within the activated pulldown,
  // return it the menubar item (anchor or togglebutton) which triggered it.
  // This way the focus stays crealy visible (in case of :focus-within / keyboard interaction)
  // and it's not up to the browser magic to determine where to move focus to when doing tab.
  if (!openstate)
  {
    //console.log(openedmenutree, branchnode);

    // First reset the info on which menu is open
    // otherwise the focus() will trigger focus events which in turn may again try to close..
    if (openedmenutree == branchnode)
    {
      openedmenutree = null;
      openedmenutree_panel = null;
      openedmenutree_panel_iscustom = false;
    }

    if (subitems_panel && subitems_panel.contains(document.activeElement))
    {
      // console.log("Focus is in the panel was are closing");

      if (openedmenutree_activatornode)
      {
        // console.log("setting focus to", openedmenutree_activatornode);
        openedmenutree_activatornode.focus();
        preventFocusStealing();
      }
    }

    openedmenutree_activatornode = null;
  }


  if (openstate)
    subitems_panel.removeAttribute("hidden");
  else
    subitems_panel.setAttribute("hidden", "");


  branchnode.classList.toggle("spc-menubar__item--expand", openstate);
}

function doToggleMenuItems(evt)
{
  // console.info("doToggleMenuItems", evt.target);

  let togglenode = evt.target.closest(".spc-menubar__item__toggle");
  if (!togglenode)
    return;

  evt.preventDefault();
  evt.stopPropagation();

  let branchnode = evt.target.closest(".spc-menubar__item--hassubitems");
  if (!branchnode)
  {
    console.error("Toggle button doesn't have a parent with .spc-menubar__item--hassubitems");
    return;
  }

  evt.preventDefault();

// inMenuToplevel()
  let in_pulldown = evt.target.closest(".spc-menubar__pulldown");
  if (!in_pulldown)
  {
    let isopen = toggleMenuBranch(branchnode, true); //, evt.target);
    if (isopen)
    {
      openedmenutree_activatornode = evt.target;
    }
    return;
  }
  else
  {
    let isopen = toggleMenuBranch(branchnode, false); //, evt.target);
  }
}
