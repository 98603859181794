import "./publication.scss";
import "./publication-documents.scss";

import * as dompack from "dompack";
// import * as whintegration from "@mod-system/js/wh/integration";


/*
// Ensure <h2> between .publication__document--attachment items.
// (because attachments don't get a color panel or border two of them adjecent have no border between their content)
function updateHorizontalRulers()
{
  console.info("updateHorizontalRulers");

  let resultsnode = document.querySelector(".publicationdocuments-items");

  let node = resultsnode.firstElementChild;
  let current_type  = ""
    , previous_type = ""
    , current_hr = null
    , after_hr = null;

  while(node)
  {
    previous_type = current_type;
    after_hr = current_hr;

    if (node.classList.contains("notmatching"))
    {
      node = node.nextElementSibling;
      continue;
    }

    if (node.tagName == "HR")
    {
      // successive <hr> ? then remove the previous one.
      if (after_hr)
        after_hr.remove();

      current_hr = node;
    }
    else if (node.classList.contains("publication__document"))
    {
      current_type = node.dataset.type;
      current_hr = null;
      console.log(current_type, "after", previous_type, "hr before?", after_hr);

      if (previous_type == "attachment"
          && current_type == "attachment")
      {
        console.info("Successive attachment");

        if (!after_hr)
          resultsnode.insertBefore(document.createElement("hr"), node);
      }
      else if (after_hr)
      {
        console.info("Remove ruler before", after_hr);
        after_hr.remove(); // remove to previous <hr> (which may even be above some hidden/notmatching items)
      }
    }

    node = node.nextElementSibling;
  }

  if (after_hr)
    after_hr.remove();
}
*/
